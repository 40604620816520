angular.module('cashier', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('cashier').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('cashier', {
        url         : "/cashier",
        templateUrl : server+"/views/m/cashier",
        controller  : 'cashier.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Caixa'
        }
    })
    .state('voucher', {
        url         : "/voucher",
        templateUrl : server+"/views/m/cashier/voucher",
        controller  : 'voucher.list',
        resolve     : {
            loginRequired: loginRequired
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Voucher'
        }
    })
    .state('checkout', {
        url         : "/checkout/:id",
        templateUrl : server+"/views/public/checkout",
        controller  : 'checkout',
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Finalizar compra'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('cashier').
controller('cashier.list', ['$scope', '$http', '$auth', '$q', '$uibModal', '$sale', 'ngToast', 'NgTableParams', '$invoice', '$service', '$rootScope', '$localStorage', '$cashier_movement', '$users', '$client', 'find', '$state', '$uibModalInstance', '$suggestion', '$financial_entry',
function($scope, $http, $auth, $q, $uibModal, $sale, ngToast, NgTableParams, $invoice, $service, $rootScope, $localStorage, $cashier_movement, $users, $client, find, $state, $uibModalInstance, $suggestion, $financial_entry) {

    var sales = [];

    var movements =[];
    $scope.modal = false;

    $scope.user = $localStorage.user;
    $scope.company = $localStorage.company;

    $scope.address = {
        zip_code  : '', 
        city_id : {},
        state_id : {}
    }

    $scope.reportData = {
        payments: []
    }

    $scope.descriptionSuggestions  = '';
    $scope.showPaymentDetailPaid   = false;
    $scope.showPaymentDetailClosed = false;
    $scope.searchFinder            = '';
    $scope.codFinder               = '';
    $scope.today                   = new moment();
    $scope.real_today              = new moment();
    $scope.waiting                 = false;
    $scope.closeX                  = false;
    $scope.devolutionState         = false;
    $scope.devolution_params       = false;
    $scope.viewDevolutionItems     = false;
    $scope.has_payment_later       = false;
    $scope.showTotals              = false;
    $scope.hasPermissionUser       = false;
    $scope.hasDiscount             = false;
    $scope.blockLabels             = false;
    $scope.searched                = false;
    $scope.devolution_avulse_items = [];

    $scope.today_opened = {
        opened: false
    }
    $scope.totals       = {
        opened      : { count : 0, value : 0 },
        succeeded   : { count : 0, value : 0 },
        failed      : { count : 0, value : 0 }
    }
    $scope.filter       = {
        day         : $scope.today.date()
    };
    $scope.filterReport = {
        date         : $scope.today.toDate(),
        user_id     : null
    };
    $scope.filterMovement = {
        day         : $scope.today.date()
    };

    $scope.movement = {
        date : null,
        description : '',
        type : '',
        value : null
    };
    $scope.date = {
        start_date: null,
        end_date: null
    }
    $scope.start_date = {
        opened: false
    }

    $scope.end_date = {
        opened: false
    }

    $scope.paramsInvoiceDevolution = {
        refNFe : null,
        items : []
    }

    $scope.paymentMethodTab = false;
    $scope.usersTab = false;
    $scope.contractTab = false;

    if (!$localStorage.filterListSales) {
		$localStorage.filterListSales = {
			from: "cashier",
			status: "all_opened",
		};
	}

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: Infinity,
        day: $scope.today.format('YYYY-MM-DD'),
        filter : $localStorage.filterListSales ?? {
			from: "cashier",
			status: "all_opened",
		}
    }, {
        counts: [],
        filterOptions: {
            filterDelay: 2000
        },
        getData: function(params) {
            $scope.waiting = true;
            return $sale.query(params.url()).$promise.then(function(data) {
                $localStorage.filterListSales = params.filter();

                if ($scope.showTotals) {
                    var opened = _.where(data.data, {status: 'pending'});
                    if(opened.length > 0){
                        $scope.totals.opened.count = opened.length;
                        $scope.totals.opened.value = _.reduce(opened, function(memo, row){ return memo + parseFloat(row.amount); }, 0);
                    }
    
                    var succeeded = _.where(data.data, {status: 'succeeded'});
                    if(succeeded.length > 0){
                        $scope.totals.succeeded.count = succeeded.length;
                        $scope.totals.succeeded.value = _.reduce(succeeded, function(memo, row){ return memo + parseFloat(row.amount); }, 0);
                    }
    
                    var failed = _.where(data.data, {status: 'failed'});
                    if(failed.length > 0){
                        $scope.totals.failed.count = failed.length;
                        $scope.totals.failed.value = _.reduce(failed, function(memo, row){ return memo + parseFloat(row.amount); }, 0);
                    }
                }

                $scope.createDaysOfMonth();
                params.total(data.total);
                $scope.waiting = false;
                return data.data;
            });
        }
    });

    $scope.validSeller = function() {
        if( ($scope.company.change_seller && $scope.company.change_seller.includes('with_password'))  && $scope.user.data && $scope.user.data.role && $scope.user.data.role.seller ){
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirmUser.modal.html',
                size: 'md',
                scope: $scope,
                backdrop        : 'static',
                keyboard        : false,
                controller: ['$scope', '$uibModalInstance', '$users',
                function($scope, $uibModalInstance, $users) {
                    $scope.title = 'Selecione o vendedor';
                    $scope.success_text = 'Confirmar';
                    $scope.sellers = [];
                    $scope.credentials = {
                        user_id     : null,
                        password    : null
                    }
                    $scope.seller = null;

                    $users.query({sellers : true, count: 100}).$promise.then(function(response){
                        $scope.sellers = response.data;
                    });

                    $scope.selectUser = function(user){
                        $scope.credentials.user_id  = user.id;
                        $scope.seller               = user;
                        $rootScope.setFocusID('passwordInput');
                    }

                    $scope.confirm = function(){
                        $users.confirm($scope.credentials).then(function(response){
                            $uibModalInstance.close($scope.seller);
                        });
                    }
                }]
            });
    
            modalInstance.result.then(function(user) {
                $scope.newSale.seller_id    = user.id;
                $scope.newSale.seller       = user;
            });
        }
    }

    $scope.resetNewSale = function(page = 'newSale') {
        $scope.container = page;
        
        $scope.newSale = {
            items           : [],
            payments        : [],
            value           : 0,
            discount        : 0,
            total           : 0,
            reduction_value : 0,
            seller_id       : null,
            shipping_value  : 0,
            seller          : null,
            itemsSupplies   : true,
        }

        setTimeout(function () {
            $scope.newSale.seller_id = 
                $scope.company.change_seller && $scope.company.change_seller.includes('clear_user') 
                    ? null 
                    : $scope.user.id;
        }, 100);

        switch ($scope.container) {
            case 'newSale':
                $rootScope.setFocusID('codFinder');
                $scope.validSeller();
                break;
            case 'report':
                $scope.searched = false;
                // $scope.loadReport();
                break;
            case 'administrative':
                $scope.loadMovements();
                break;
            case 'shipping':
                $scope.showSendShipping();
                break;
            default:
                $scope.tableParams._params.day = $scope.today.format('YYYY-MM-DD');
                $scope.totals       = {
                    opened      : { count : 0, value : 0 },
                    succeeded   : { count : 0, value : 0 },
                    failed      : { count : 0, value : 0 }
                }
        }
        
        $scope.closeX      = false;
        $scope.note        = false;
        $scope.blockLabels = false;
        
    }
    $scope.resetNewSale();
    

    $scope.checkCanShipping = function(data){
        if ($scope.newSale.items.length > 0) {
            return $scope.newSale.items.some(function(row){ return row.service.type === 'P'});
        } else {
            return false;
        }
    }


    $scope.searchProduct = function () {
		var str = document.getElementById("codFinder").value;
		if (str.length == 0) {
			if ($scope.newSale.payments.length == 0) {
				$scope.changePayment();
			} else {
				if ($scope.company.close_sale_with_enter) {
					if (
						$scope.company.change_seller &&
						$scope.company.change_seller === "confirm"
					) {
						$scope.checkoutWithSellerParam();
					} else {
						$scope.checkout();
					}
				}
			}

			return;
		}

		var qtd = 1;
		var allow_discount = 1;
		if (str.includes("*")) {
			var splitQtd = str.split("*");
			qtd = splitQtd[0].trim();
			str = splitQtd[1].trim();
		}
		var percentDiscount = 0;
		var valueDiscount = 0;
		if (str.includes("%") || str.includes("$")) {
			if (str.includes("%")) {
				var splitPercentDiscount = str.split("%");
				percentDiscount = splitPercentDiscount[0].trim();
				str = splitPercentDiscount[1].trim();
			} else if (str.includes("$")) {
				var splitValueDiscount = str.split("$");
				valueDiscount = splitValueDiscount[0].trim();
				str = splitValueDiscount[1].trim();
			}
			allow_discount = 0;
		}

		if (qtd <= 0) {
			ngToast.danger({
				content: "Quantidade mínima deve ser 1",
				dismissOnTimeout: true,
				timeout: 5000,
			});
			return;
		}

		$service
			.codOrRef({
				find: str,
				user_id: $scope.userSelected.id,
				discount: percentDiscount,
			})
			.then(
				function (data) {
					if (data) {
						if (data.length == 1) {
							if (
								data[0].stock_control == 1 &&
								parseFloat(data[0].real_stock) < parseFloat(qtd)
							) {
								ngToast.danger({
									content: "Estoque insuficiente!",
									dismissOnTimeout: true,
									timeout: 5000,
								});
								return;
							}

							if (
								(data[0].value === null ||
									data[0].value === 0) &&
								data[0].value_type != "manual"
							) {
								$scope.addItem(str, qtd, null);
								document.getElementById("codFinder").value = "";
								return;
							}

							let value =
								data[0].value > 0
									? data[0].value
									: data[0].value_calculed;
							if (
								percentDiscount > 0 &&
								data[0].value_type == "manual"
							) {
								value = (
									value -
									(value * percentDiscount) / 100
								).toFixed(2);
							}

							if (
								valueDiscount > 0 &&
								data[0].value_type == "manual"
							) {
								value = (value - valueDiscount).toFixed(2);
							}

							if (data[0].offers.length > 0) {
								let offer_id = null;
								_.each(data[0].offers, function (offer) {
									if (
										offer.available &&
										parseFloat(qtd) >=
											parseFloat(offer.minimum_quantity)
									) {
										value = offer.price_calculed;
										offer_id = offer.id;
									}
								});
								$scope.newSale.items.push({
									external_reference:
										data[0].external_reference,
									service_id: data[0].id,
									name: data[0].name,
									description: data[0].description,
									value: value,
									quantity: qtd,
									discount: 0,
									reduction_value: 0,
									offer_id: offer_id,
									total:
										data[0].total > 0
											? data[0].total
											: value * qtd,
									allow_discount: offer_id
										? 0
										: allow_discount,
									service_value_type: data[0].value_type,
									old_value: data[0].value,
									shipping_value: 0,
									service: data[0],
								});
							} else {
								if (
									data[0].value == 0 &&
									data[0].value_type == "manual"
								) {
									var modalInstance = $uibModal.open({
										animation: true,
										templateUrl:
											"selectCurrency.modal.html",
										size: "sm",
										scope: $scope,
										controller: [
											"$rootScope",
											"$state",
											"$scope",
											"$uibModalInstance",
											function (
												$rootScope,
												$state,
												$scope,
												$uibModalInstance
											) {
												setTimeout(function () {
													$rootScope.setFocusID(
														"valueField"
													);
												}, 30);

												$scope.title =
													"Digite o valor:";
												$scope.cancel_text = "Não";
												$scope.success_text = "Sim";

												$scope.input = {
													label: "Valor",
													value: 0,
												};

												$scope.confirm = function () {
													$uibModalInstance.close(
														$scope.input.value
													);
												};

												$scope.cancel = function () {
													$uibModalInstance.dismiss(
														"cancel"
													);
												};
											},
										],
									});

									modalInstance.result.then(function (
										valueManual
									) {
										$scope.newSale.items.push({
											external_reference:
												data[0].external_reference,
											service_id: data[0].id,
											name: data[0].name,
											description: data[0].description,
											value: valueManual,
											quantity: qtd,
											discount: 0,
											reduction_value: 0,
											offer_id: null,
											total:
												data[0].total > 0
													? data[0].total
													: valueManual * qtd,
											allow_discount:
												data[0].offers.length > 0
													? 0
													: allow_discount,
											service_value_type:
												data[0].value_type,
											old_value: valueManual,
											shipping_value: 0,
											service: data[0],
										});
										$scope.removeDiscount();
										$scope.newSale.itemsTotal = _.reduce(
											$scope.newSale.items,
											function (memo, row) {
												return (
													memo +
													parseFloat(row.quantity)
												);
											},
											0
										);
									});
									document.getElementById("codFinder").value =
										"";
									return;
								} else {
									$scope.newSale.items.push({
										external_reference:
											data[0].external_reference,
										service_id: data[0].id,
										name: data[0].name,
										description: data[0].description,
										value: value,
										quantity: qtd,
										discount: 0,
										reduction_value: 0,
										offer_id: null,
										total:
											data[0].total > 0
												? data[0].total
												: value * qtd,
										allow_discount:
											data[0].offers.length > 0
												? 0
												: allow_discount,
										service_value_type: data[0].value_type,
										old_value: data[0].value,
										shipping_value: 0,
										service: data[0],
									});
								}
							}
							$scope.removeDiscount();
							$scope.newSale.itemsTotal = _.reduce(
								$scope.newSale.items,
								function (memo, row) {
									return memo + parseFloat(row.quantity);
								},
								0
							);
							if ($scope.company.save_sale_automatic)
                                $scope.save(
                                    false, 
                                    $scope.newSale.status == 'succeeded' ? $scope.newSale.status : 'budget',
                                    true,
                                );
						} else {
							$scope.addItem(str, qtd, null);
						}
					} else {
						$scope.addItem(str, qtd, null);
					}
					document.getElementById("codFinder").value = "";
				},
				function (error) {
					console.log(error);
					if (error.data.message == "limit_discount");
					{
						ngToast.danger({
							content: "Limite de desconto do usuário excedido!",
							dismissOnTimeout: true,
							timeout: 5000,
						});
						$uibModalInstance.close();
					}
					$scope.addItem(str, qtd);
				}
			);
	};

	$scope.searchingClient = function () {
		var str = document.getElementById("find").value;
		if (str.length == 0) return;

		$client.query({ find: str, "filter[type]": "client" }).$promise.then(
			function (response) {
				if (response) {
					if (response.data.length == 1) {
						$scope.showClient(response.data[0].id);
						return;
					}
					if (response.data.length > 1) {
						$scope.addClient(str);
						return;
					}
					if (response.data.length == 0) {
						$scope.createClient(str);
						return;
					}
				} else {
					$scope.addClient();
				}
				document.getElementById("find").value = "";
			},
			function (error) {
				$scope.addClient();
			}
		);
		document.getElementById("find").value = "";
	};

	$scope.filterSale = function (from, array) {
		return _.where(array, { from: from });
	};

	$scope.searchingDetail = function () {
		var str = document.getElementById("findDetail").value;
		if (str == "") {
			ngToast.warning({
				content: "Insira os dados da busca!",
				dismissOnTimeout: true,
				timeout: 5000,
			});
			return;
		}
		var modalInstance = $uibModal.open({
			animation: true,
			templateUrl: "search.modal.html",
			size: "lg",
			controller: "searching_sale",
			backdrop: "static",
			resolve: {
				id: function () {
					return $scope.container;
				},
			},
		});

		modalInstance.result.then(function (data = null) {
			if (data) {
				if (data.funciton) {
					switch (data.funciton) {
						case "openSale":
							$scope.openSale(data);
							break;
					}
					return;
				} else {
					if (data.find) {
						$scope.newSale.devolution_items = data.find;
						_.each(
							$scope.newSale.devolution_items.items,
							function (row) {
								return (row.reduction_value = row.value);
							}
						);
						$scope.items_devolution = data.find.items;
						_.each($scope.items_devolution, function (row) {
							return (row.reduction_value = row.value);
						});
						$scope.newSale.reduction_value =
							data.find.reduction_value;
						$scope.calcTotal();
					}
				}
			}
		});
	};

	$scope.viewDevolution_items = function () {
		$scope.viewDevolutionItems = true;
	};

	$scope.returnClose = function () {
		$scope.viewDevolutionItems = false;
	};

	$scope.return = function () {
		$uibModalInstance.close();
	};

	$scope.filterFrom = function (data) {
		$scope.tableParams.filter.from = data;
		$scope.tableParams.reload();
	};

	$scope.selectedMonth = function (int) {
		if ($scope.filter.day > 28) {
			$scope.filter.day = $scope.filter.day - 3;
		}
		$scope.today.month($scope.today.month() + parseInt(int));
		$scope.today.date($scope.filter.day);

		switch ($scope.container) {
			case "report":
				// $scope.loadReport();
				break;
			case "administrative":
				$scope.loadMovements();
				break;
			case "shipping":
				$scope.tableShippingParams._params.month =
					$scope.today.format("YYYY-MM-DD");
				break;
			default:
				$scope.tableParams._params.day =
					$scope.today.format("YYYY-MM-DD");
				$scope.totals = {
					opened: { count: 0, value: 0 },
					succeeded: { count: 0, value: 0 },
					failed: { count: 0, value: 0 },
				};
		}
	};

	$scope.changeDayFilter = function (day) {
		$scope.filter.day = day;
		$scope.today.date($scope.filter.day);
		$scope.tableParams._params.day = $scope.today.format("YYYY-MM-DD");
		$scope.totals = {
			opened: { count: 0, value: 0 },
			succeeded: { count: 0, value: 0 },
			failed: { count: 0, value: 0 },
		};
		$scope.tableParams.reload();
	};

	$scope.changeDayFilterMovement = function (day) {
		$scope.filterMovement.day = day;
		$scope.today.date($scope.filter.day);
		$scope.loadMovements();
	};

	$scope.calcTotal = function (cleanPayments = true) {
		if (cleanPayments) {
			$scope.newSale.payments = [];
		}
		$scope.newSale.value = _.reduce(
			$scope.newSale.items,
			function (memo, row) {
				return memo + parseFloat(row.value) * row.quantity;
			},
			0
		);
		$scope.newSale.total = $scope.newSale.value;
		var rValue = _.reduce(
			_.filter($scope.newSale.items, (row) => {
				return (
					row.allow_discount == 1 &&
					row.service_value_type != "blocked"
				);
			}),
			function (memo, row) {
				return memo + parseFloat(row.value) * row.quantity;
			},
			0
		);
		var sumDiscount = 0.0;
		var percent =
			$scope.newSale.discount > 0 ? $scope.newSale.discount / rValue : 0;
		_.each($scope.newSale.items, function (item) {
			item.reduction_value = 0;
			item.total = item.value * item.quantity;
			if (
				item.allow_discount === 1 &&
				item.service_value_type != "blocked"
			) {
				item.discount =
					percent > 0
						? (item.value * item.quantity * percent).toFixed(2)
						: 0;
				item.total = (
					item.value * item.quantity -
					parseFloat(item.discount)
				).toFixed(2);
				sumDiscount = (
					parseFloat(sumDiscount) + parseFloat(item.discount)
				).toFixed(2);
			}
		});
		if (sumDiscount > $scope.newSale.discount) {
			var itemDiscount = _.find($scope.newSale.items, (row) => {
				return (
					row.allow_discount === 1 &&
					row.service_value_type !== "blocked"
				);
			});
			itemDiscount.discount = (
				itemDiscount.discount -
				(sumDiscount - $scope.newSale.discount)
			).toFixed(2);
			itemDiscount.total = (
				itemDiscount.value * itemDiscount.quantity -
				itemDiscount.discount
			).toFixed(2);
		}

		if (sumDiscount < $scope.newSale.discount) {
			var itemDiscount = _.find($scope.newSale.items, (row) => {
				return (
					row.allow_discount === 1 &&
					row.service_value_type !== "blocked"
				);
			});
			itemDiscount.discount = (
				parseFloat(itemDiscount.discount) +
				parseFloat($scope.newSale.discount - sumDiscount)
			).toFixed(2);
			itemDiscount.total = (
				itemDiscount.value * itemDiscount.quantity -
				itemDiscount.discount
			).toFixed(2);
		}

		if ($scope.newSale.reduction_value > 0) {
			$scope.newSale.value = _.reduce(
				$scope.newSale.items,
				function (memo, row) {
					return (
						memo +
						(parseFloat(row.value) * row.quantity +
							parseFloat(row.discount))
					);
				},
				0
			);
			$scope.newSale.total = $scope.newSale.value;

			var rValue = $scope.newSale.total;
			var sumDiscount = 0.0;
			var percent =
				$scope.newSale.reduction_value > 0
					? $scope.newSale.reduction_value / rValue
					: 0;
			_.each($scope.newSale.items, function (item) {
				item.reduction_value =
					percent > 0 ? (item.total * percent).toFixed(2) : 0;
				item.total = item.total - item.reduction_value;
				sumDiscount = sumDiscount + parseFloat(item.reduction_value);
			});
			if (sumDiscount > $scope.newSale.reduction_value) {
				$scope.newSale.items[0].reduction_value = (
					$scope.newSale.items[0].reduction_value -
					(sumDiscount - $scope.newSale.reduction_value)
				).toFixed(2);
				if ($scope.newSale.discount > 0) {
					$scope.newSale.items[0].total = (
						$scope.newSale.items[0].value *
							$scope.newSale.items[0].quantity -
						$scope.newSale.items[0].reduction_value -
						parseFloat($scope.newSale.items[0].discount)
					).toFixed(2);
				} else {
					$scope.newSale.items[0].total = (
						$scope.newSale.items[0].value *
							$scope.newSale.items[0].quantity -
						$scope.newSale.items[0].reduction_value
					).toFixed(2);
				}
			}
			if (sumDiscount < $scope.newSale.reduction_value) {
				$scope.newSale.items[0].reduction_value = (
					parseFloat($scope.newSale.items[0].reduction_value) +
					parseFloat($scope.newSale.reduction_value - sumDiscount)
				).toFixed(2);
				if ($scope.newSale.discount > 0) {
					var validateTotal = (
						$scope.newSale.items[0].value *
							$scope.newSale.items[0].quantity -
						$scope.newSale.items[0].discount
					).toFixed(2);
					if (
						validateTotal <
						parseFloat($scope.newSale.items[0].reduction_value)
					) {
						var diference =
							parseFloat(
								$scope.newSale.items[0].reduction_value
							) - validateTotal;
						$scope.newSale.items[0].reduction_value =
							parseFloat(
								$scope.newSale.items[0].reduction_value
							) - diference;
						$scope.newSale.items[1].reduction_value =
							parseFloat(
								$scope.newSale.items[1].reduction_value
							) + diference;
						$scope.newSale.items[1].total =
							$scope.newSale.items[1].value *
								$scope.newSale.items[1].quantity -
							$scope.newSale.items[1].discount -
							$scope.newSale.items[1].reduction_value;
					}
					$scope.newSale.items[0].total = (
						$scope.newSale.items[0].value *
							$scope.newSale.items[0].quantity -
						$scope.newSale.items[0].reduction_value -
						$scope.newSale.items[0].discount
					).toFixed(2);
				} else {
					$scope.newSale.items[0].total = (
						$scope.newSale.items[0].value *
							$scope.newSale.items[0].quantity -
						$scope.newSale.items[0].reduction_value
					).toFixed(2);
				}
			}
		}
		$scope.newSale.itemsValue = _.reduce(
			$scope.newSale.items,
			function (memo, row) {
				return parseFloat(memo) + parseFloat(row.total);
			},
			0
		);
		$scope.newSale.total =
			( parseFloat($scope.newSale.itemsValue) +
			parseFloat($scope.newSale.shipping_value) );
		$scope.newSale.value =
			$scope.newSale.total +
			parseFloat($scope.newSale.discount) +
			parseFloat($scope.newSale.reduction_value);
		$scope.newSale.itemsTotal = _.reduce(
			$scope.newSale.items,
			function (memo, row) {
				return memo + parseFloat(row.quantity);
			},
			0
		);

		$rootScope.setFocusID("codFinder");
	};

	$scope.removeItem = function (index) {
		if ($scope.newSale.reduction_value) {
			$scope.removeDevolution();
		}
		if (
			$scope.company.delete_item_sale &&
			$scope.company.delete_item_sale.includes("with_password")
		) {
			var modalInstance = $uibModal.open({
				animation: true,
				templateUrl: "confirm.modal.html",
				size: "sm",
				scope: $scope,
				controller: [
					"$state",
					"$scope",
					"$uibModalInstance",
					"$client",
					"$http",
					"ngToast",
					"$user_role",
					function (
						$state,
						$scope,
						$uibModalInstance,
						$client,
						$http,
						ngToast,
						$user_role
					) {
						$scope.title =
							"Usuários com permissão para excluir items!";
						$scope.cancel_text = "Cancelar";
						$scope.success_text = "Confirmar";
						$scope.permissionsHeight = [];

						$scope.filter = {
							count: 10,
							permission_name: "cashier_item_delete",
						};

						$users
							.permissions($scope.filter)
							.then(function (response) {
								$scope.permissionsHeight = response;
							});

						$scope.pCredntials = true;
						$scope.credentials = {
							user_id: "",
							password: "",
						};

						$scope.confirm = function () {
							$users.confirm($scope.credentials).then(
								function (response) {
									$scope.$parent.newSale.items.splice(
										index,
										1
									);
									$scope.removeShipping();
									$scope.calcTotal();
									if ($scope.company.save_sale_automatic)
                                        $scope.save(
                                            false, 
                                            $scope.newSale.status == 'succeeded' ? $scope.newSale.status : 'budget',
                                            true,
                                        );
									$uibModalInstance.close();
								},
								function (error) {
									ngToast.danger({
										content: "Erro ao remover item",
										dismissOnTimeout: true,
										timeout: 1000,
									});
								}
							);
						};

						$scope.cancel = function () {
							$uibModalInstance.dismiss("cancel");
						};
					},
				],
			});
		} else {
			var modalInstance = $uibModal.open({
				animation: true,
				templateUrl: "confirm.modal.html",
				size: "sm",
				scope: $scope,
				controller: [
					"$state",
					"$scope",
					"$uibModalInstance",
					function ($state, $scope, $uibModalInstance) {
						$scope.title = "Deseja remover esse item?";
						$scope.cancel_text = "Não";
						$scope.success_text = "Sim";

						$scope.confirm = function () {
							$scope.$parent.newSale.items.splice(index, 1);
							$scope.removeShipping();
							$scope.calcTotal();
							if ($scope.company.save_sale_automatic)
                                $scope.save(
                                    false, 
                                    $scope.newSale.status == 'succeeded' ? $scope.newSale.status : 'budget',
                                    true,
                                );
							$uibModalInstance.close();
						};

						$scope.cancel = function () {
							$uibModalInstance.dismiss("cancel");
						};
					},
				],
			});
		}
	};

	$scope.noteItem = function (item) {
		var modalInstance = $uibModal.open({
			animation: true,
			templateUrl: "note_item.modal.html",
			size: "md",
			scope: $scope,
			backdrop: "static",
			controller: [
				"$scope",
				"$uibModalInstance",
				"$q",
				function ($scope, $uibModalInstance, $q) {
					$scope.item = {};
					$scope.item = item;
					$scope.title = "Observação do item";
					$scope.note = $scope.item.note;
					$scope.cancel_text = "Cancelar";
					$scope.success_text = "OK";

					$scope.confirm = function () {
						$uibModalInstance.close();
					};

					$scope.cancel = function () {
						$scope.item.note = $scope.note;
						$uibModalInstance.close();
					};
				},
			],
		});
	};

	$scope.createDaysOfMonth = function (data) {
		// $sale.query({month: $scope.today.toDate()}).$promise.then(function(response){
		$scope.today = moment($scope.today);
		$scope.dates = [];
		$scope.datesReport = [];
		for (var i = 1; i <= $scope.today.daysInMonth(); i++) {
			switch (i) {
				// case parseInt($scope.real_today.format('DD')):
				//     $scope.dates.push({
				//         day: i,
				//         class: 'current'
				//     });
				//
				//     $scope.datesReport.push({
				//         day: i,
				//         class: 'current'
				//     });
				// break;
				//
				// case _.filter(response.data, function(data){
				//     return  parseInt(moment(data.closed_at).format('D')) === parseInt(i)
				// }).length > 0 ? i : 0 :
				//     $scope.dates.push({
				//         day: i,
				//         class: 'success'
				//     });
				//
				//     $scope.datesReport.push({
				//         day: i,
				//         class: 'success'
				//     });
				// break;

				default:
					$scope.dates.push({
						day: i,
						class: "",
					});

					$scope.datesReport.push({
						day: i,
						class: "",
					});
					break;
			}
			if (i == parseInt($scope.filter.day))
				$scope.dates[$scope.dates.length - 1].class = "selected";
		}

		// });
	};

	$scope.createDaysOfMonthMovement = function (data) {
		$cashier_movement
			.query({ month: $scope.today.toDate() })
			.$promise.then(function (response) {
				$scope.today = moment($scope.today);
				$scope.dates = [];
				$scope.datesMovement = [];
				for (var i = 1; i <= $scope.today.daysInMonth(); i++) {
					switch (i) {
						case parseInt($scope.real_today.format("DD")):
							$scope.dates.push({
								day: i,
								class: "current",
							});
							$scope.datesMovement.push({
								day: i,
								class: "current",
							});
							break;

						case _.filter(response.movementsMonth, function (data) {
							return (
								parseInt(
									moment(data.created_at).format("D")
								) === parseInt(i)
							);
						}).length > 0
							? i
							: 0:
							$scope.dates.push({
								day: i,
								class: "success",
							});
							$scope.datesMovement.push({
								day: i,
								class: "success",
							});
							break;

						default:
							$scope.dates.push({
								day: i,
								class: "",
							});
							$scope.datesMovement.push({
								day: i,
								class: "",
							});
							break;
					}
					if (i == parseInt($scope.filter.day))
						$scope.dates[$scope.dates.length - 1].class =
							"selected";

					if (i == parseInt($scope.filterMovement.day))
						$scope.datesMovement[
							$scope.datesMovement.length - 1
						].class = "selected";
				}
			});
	};

	$scope.tableShippingParams = new NgTableParams(
		{
			page: 1,
			count: Infinity,
			only_shipping: true,
			month: $scope.today.format("YYYY-MM-DD"),
		},
		{
			counts: [],
			filterDelay: 0,
			getData: function (params) {
				$scope.waiting = true;
				return $sale.query(params.url()).$promise.then(function (data) {
					params.total(data.total);
					$scope.waiting = false;
					return data.data;
				});
			},
		}
	);

	$scope.getTotalsFromList = function () {
		$scope.showTotals = true;
		$scope.tableParams.reload();
	};

	$scope.hideTotalsFromList = function () {
		$scope.showTotals = false;
	};

	$scope.status = [
		{ id: "", title: "Todos" },
		{ id: "all_opened", title: "Abertas e Orçamentos" },
		{ id: "opened", title: "Aberta" },
		{ id: "canceled", title: "Cancelada" },
		{ id: "budget", title: "Orçamento" },
		{ id: "succeeded", title: "Fechada" },
		{ id: "payment_later", title: "Posterior" },
	];

	$scope.payments = [
		{ id: "", title: "Todos" },
		{ id: "Dinheiro", title: "Dinheiro" },
		{ id: "Credito", title: "Crédito" },
		{ id: "Debito", title: "Débito" },
		{ id: "Boleto", title: "Boleto" },
		{ id: "Outros", title: "Outros" },
		{ id: "Pix", title: "Pix" },
		{ id: "payment_later", title: "Posterior" },
	];

	$scope.removeDiscount = function () {
		$scope.newSale.discount = 0;
		_.each($scope.newSale.items, function (item) {
			item.discount = 0;
		});
		$scope.hasDiscount = false;
		$scope.calcTotal();
	};

	$scope.removeDevolution = function () {
		$scope.newSale.reduction_value = 0;
		$scope.devolution_avulse_items = [];
		$scope.calcTotal();
	};

	$users.query({ count: 1000 }).$promise.then(function (response) {
		$scope.users = response.data;
		_.each($scope.users, function (row) {
			if (row.user_data_id == $scope.user.data.id) {
				$scope.userSelected = row;
			}
		});
	});

	$scope.makeDiscount = function () {
		if ($scope.newSale.total < 0) {
			ngToast.danger({
				content: "Adicione itens na venda primeiro",
				dismissOnTimeout: true,
				timeout: 5000,
			});
			return;
		}
		var modalInstance = $uibModal.open({
			animation: true,
			ariaLabelledBy: "modal-title",
			ariaDescribedBy: "modal-body",
			templateUrl: server + "/views/m/common/discount",
			controller: "discount",
			size: "lg",
			resolve: {
				protocol: function () {
					return angular.copy($scope.newSale);
				},
				users: function () {
					return $scope.userSelected;
				},
			},
		});

		modalInstance.result.then(function (protocol) {
			var itemDiscount = _.find($scope.newSale.items, {
				allow_discount: 1,
			});
			if (!itemDiscount) {
				ngToast.danger({
					content: "Nenhum item permite desconto!",
					dismissOnTimeout: true,
					timeout: 5000,
				});
				return;
			}
			$scope.newSale.discount = protocol.discount;
			if ($scope.newSale.discount > 0) {
				$scope.hasDiscount = true;
			}
			$scope.cleanParameter = false;
			$scope.calcTotal();
		});
	};

    $scope.lookingProducts = function() {
        var modalInstance = $uibModal.open({
			animation: true,
			ariaLabelledBy: "modal-title",
			ariaDescribedBy: "modal-body",
			templateUrl: server + "/views/m/common/add_service",
			controller: "add_service",
			controllerAs: "$ctrl",
			size: "lg",
			backdrop: "static",
			resolve: {
				origin: function () {
					return 'cashier_looking';
				},
			},
		});
    }

	$scope.addItem = function (find = null, qtd = null, origin = "cashier") {
		var modalInstance = $uibModal.open({
			animation: true,
			ariaLabelledBy: "modal-title",
			ariaDescribedBy: "modal-body",
			templateUrl: server + "/views/m/common/add_service",
			controller: "add_service",
			controllerAs: "$ctrl",
			size: "lg",
			backdrop: "static",
			resolve: {
				find: function () {
					return find;
				},
				qtd: function () {
					return qtd ? qtd : 1;
				},
				origin: function () {
					return origin;
				},
			},
		});

		modalInstance.result.then(function (data) {
            if ( Array.isArray( data ) ) {
				_.each(data, function (item) {
					$scope.newSale.items.push({
						service_id: item.service_id,
						name: item.name,
						description: item.obj.description,
						value:
							item.value > 0
								? parseFloat(item.value).toFixed(2)
								: parseFloat(item.obj.value_calculed).toFixed(2),
						quantity: item.quantity > 0 ? item.quantity : 1,
						discount: 0,
						reduction_value: 0,
						note: item.note,
						total:
							item.total > 0
								? parseFloat(item.total).toFixed(2)
								: (item.obj.value_calculed * item.quantity).toFixed(2),
						offer_id: item.offer_id,
						external_reference: item.obj.external_reference,
						allow_discount: item.allow_discount,
						service_value_type: item.obj.value_type,
						old_value: item.obj
							? item.obj.value_calculed
							: item.value,
						shipping_value: 0,
						service: item.obj,
					});
				});
			} else {
				$scope.newSale.items.push({
					service_id: data.service,
					name: data.name,
					description: data.obj.description,
					value:
                        data.value > 0 ?
                            parseFloat(data.value).toFixed( 2 ) :
                            parseFloat(data.obj.value_calculed).toFixed(2),
					quantity: data.quantity > 0 ? data.quantity : 1,
					discount: 0,
					reduction_value: 0,
					note: data.note,
					total:
						data.total > 0
							? parseFloat(data.total).toFixed(2)
							: (data.obj.value_calculed * data.quantity).toFixed(2),
					offer_id: data.offer_id,
					external_reference: data.obj.external_reference,
					allow_discount: data.allow_discount,
					service_value_type: data.obj.value_type,
					old_value: data.obj ? data.obj.value_calculed : data.value,
					shipping_value: 0,
                    service: data.obj,
                    selectedStockCompany: data.selectedStockCompany,
				});
			}
			$scope.removeDiscount();
			if ($scope.company.save_sale_automatic)
                $scope.save(
                    false, 
                    $scope.newSale.status == 'succeeded' ? $scope.newSale.status : 'budget',
                    true,
                );
		});
	};

	$scope.addItemDevolutionAvulse = function (
		find = null,
		qtd = null,
		origin = "cashier_devolution"
	) {
		var modalInstance = $uibModal.open({
			animation: true,
			ariaLabelledBy: "modal-title",
			ariaDescribedBy: "modal-body",
			templateUrl: server + "/views/m/common/add_service",
			controller: "add_service",
			controllerAs: "$ctrl",
			size: "lg",
			backdrop: "static",
			resolve: {
				find: function () {
					return find;
				},
				qtd: function () {
					return qtd ? qtd : 1;
				},
				origin: function () {
					return origin;
				},
			},
		});
		modalInstance.result.then(function (data) {
			if (Array.isArray(data)) {
				_.each(data, function (item) {
					$scope.devolution_avulse_items.push({
						service_id: item.service_id,
						name: item.name,
						description: item.obj.description,
						value:
							item.value > 0
								? item.value
								: item.obj.value_calculed,
						quantity: item.quantity > 0 ? item.quantity : 1,
						discount: 0,
						reduction_value:
							item.value > 0
								? item.value
								: item.obj.value_calculed,
						note: item.note,
						total:
							item.total > 0
								? item.total
								: item.obj.value_calculed * item.quantity,
						offer_id: item.offer_id,
						external_reference: item.obj.external_reference,
						allow_discount: item.allow_discount,
						service_value_type: item.obj.value_type,
					});
				});
				$scope.sumValor = _.reduce(
					$scope.devolution_avulse_items,
					function (memo, row) {
						return memo + row.value * row.quantity;
					},
					0
				);
				$scope.newSale.reduction_value = $scope.sumValor;
				$scope.calcTotal();
			} else {
				$scope.devolution_avulse_items.push({
					service_id: data.service,
					name: data.name,
					description: data.obj.description,
					value:
						data.value > 0 ? data.value : data.obj.value_calculed,
					quantity: data.quantity > 0 ? data.quantity : 1,
					discount: 0,
					reduction_value:
						data.value > 0 ? data.value : data.obj.value_calculed,
					note: data.note,
					total:
						data.total > 0
							? data.total
							: data.obj.value_calculed * data.quantity,
					offer_id: data.offer_id,
					external_reference: data.obj.external_reference,
					allow_discount: data.allow_discount,
					service_value_type: data.obj.value_type,
				});
				$scope.sumValor = _.reduce(
					$scope.devolution_avulse_items,
					function (memo, row) {
						return memo + row.value * row.quantity;
					},
					0
				);
				$scope.newSale.reduction_value = $scope.sumValor;
				$scope.calcTotal();
			}
			$scope.newSale.devolution_avulse_items =
				$scope.devolution_avulse_items;
		});
	};

	$scope.createClient = function (find) {
		var modalInstance = $uibModal.open({
			animation: true,
			templateUrl: "confirm.modal.html",
			size: "sm",
			scope: $scope,
			controller: [
				"$state",
				"$scope",
				"$uibModalInstance",
				"$client",
				function ($state, $scope, $uibModalInstance, $client) {
					$scope.title = "Cliente não encontrado!";
					$scope.subTitle = "Deseja cadastrar novo cliente?";
					$scope.cancel_text = "Não";
					$scope.success_text = "Sim";

					$scope.client = {
						client_status_id: {},
						tax_regime_id: {},
						tax_regime_status_id: {},
						status: "1",
						has_pIRRFRet: 0,
						has_pISSRet: 0,
						has_pPrevRet: 0,
						has_pCSLLRet: 0,
						has_pCOFINSRet: 0,
						has_pPISRet: 0,
						phone: find ? "61" + find : "",
					};

					$scope.confirm = function () {
						var client = $scope.client;
						client.client_status_id =
							$scope.client.client_status_id.id;
						client.tax_regime_id = $scope.client.tax_regime_id.id;
						client.tax_regime_status_id =
							$scope.client.tax_regime_status_id.id;

						$client.save(client).$promise.then(function (data) {
							$uibModalInstance.close(data);
						});
					};

					$scope.cancel = function () {
						$uibModalInstance.dismiss("cancel");
					};
				},
			],
		});

		modalInstance.result.then(function (data) {
			$scope.showClient(data.id);
		});
	};

	$scope.showClient = function (id) {
		var modalInstance = $uibModal.open({
			animation: true,
			templateUrl: server + "/views/m/client/modal",
			size: "lg",
			controller: "client.show",
			backdrop: "static",
			resolve: {
				showInModal: true,
				id: id,
			},
		});
		modalInstance.result.then(function (data) {
			$scope.newSale.buyer = data;
			$scope.newSale.client_id = data ? data.id : null;
		});
	};

	$scope.addClient = function (find = null) {
		if ($scope.newSale.buyer) {
			$scope.showClient($scope.newSale.buyer.id);
			return;
		}
		var modalInstance = $uibModal.open({
			animation: true,
			templateUrl: server + "/views/m/client", //'os.modal.html',
			size: "lg",
			controller: "client.list",
			backdrop: "static",
			resolve: {
				showInModal: true,
				find: function () {
					return find;
				},
			},
		});
		modalInstance.result.then(function (data) {
			$scope.newSale.buyer = data;
			$scope.newSale.client_id = data ? data.id : null;
			$scope.closeX = true;
			if ($scope.company.save_sale_automatic)
                $scope.save(
                    false, 
                    $scope.newSale.status == 'succeeded' ? $scope.newSale.status : 'budget',
                    true,
                );
		});
	};

	$scope.clearClient = function () {
		$scope.newSale.buyer = null;
		$scope.closeX = false;
	};

	$scope.showSale = function (id) {
		$sale.get({ id: id }).$promise.then(function (response) {
			var sale = response;
			if (sale.payments.length > 0 && sale.status == "succeeded") {
				$scope.viewPayments(sale.hashid);
			}
			if (
				sale.status == "opened" ||
				sale.status == "budget" ||
				sale.payment_later == 1
			) {
				if (sale.buyer && sale.payment_later == 0) {
					$scope.closeX = true;
				}
				$scope.openSale(sale);
			}
		});
	};

	$scope.viewPayments = function (id) {
		$sale.get({ id: id }).$promise.then(function (response) {
			var sale = response;
			var modalInstance = $uibModal.open({
				animation: true,
				templateUrl: "view_payments.modal.html",
				size: "lg",
				scope: $scope,
				backdrop: "static",
				controller: [
					"$scope",
					"$uibModalInstance",
					"Upload",
					function ($scope, $uibModalInstance) {
						$scope.title = "Venda Nº: " + sale.number;
						$scope.sub_title = "Vendedor: " + sale.seller_name;
						$scope.sub_title_2 = "Operador: " + sale.user_name;
						$scope.cancel_text = "Cancelar";
						$scope.success_text = "OK";
						$scope.server = server;

						$scope.sale = sale;
						if ($scope.sale.created_at) {
							$scope.hour = $scope.sale.created_at.substr(11);
						}

						$scope.copy = function () {
							var copyText = document.getElementById("linkSale");

							copyText.select();
							copyText.setSelectionRange(0, 99999);

							document.execCommand("copy");
						};

						$scope.confirm = function () {
							$uibModalInstance.close();
						};

						$scope.cancel = function () {
							$uibModalInstance.close();
						};
					},
				],
			});
		});
	};

	$scope.viewShipping = function (sale) {
		var modalInstance = $uibModal.open({
			animation: true,
			templateUrl: "view_shipping.modal.html",
			size: "lg",
			scope: $scope,
			backdrop: "static",
			controller: [
				"$scope",
				"$uibModalInstance",
				"$client",
				"Upload",
				"$q",
				function ($scope, $uibModalInstance, $client, Upload, $q) {
					$scope.cancel_text = "Cancelar";
					$scope.success_text = "OK";
					$scope.server = server;

					$scope.sale = sale;

					$scope.copy = function () {
						var copyText = document.getElementById("linkSale");

						copyText.select();
						copyText.setSelectionRange(0, 99999);

						document.execCommand("copy");
					};

					$scope.confirm = function () {
						$uibModalInstance.close();
					};

					$scope.cancel = function () {
						$uibModalInstance.close();
					};
				},
			],
		});
	};

	$scope.editShippingStatus = function (sale) {
		var modalInstance = $uibModal.open({
			animation: true,
			templateUrl: "edit_shipping.modal.html",
			size: "md",
			scope: $scope,
			backdrop: "static",
			controller: [
				"$scope",
				"$uibModalInstance",
				"$client",
				"Upload",
				"$q",
				"ngToast",
				function (
					$scope,
					$uibModalInstance,
					$client,
					Upload,
					$q,
					ngToast
				) {
					$scope.cancel_text = "Cancelar";
					$scope.success_text = "OK";
					$scope.server = server;

					$scope.sale = sale;

					$scope.changeShipping = function (shipping) {
						if ($scope.waiting) return;

						$scope.waiting = true;
						var data = {
							shipping_status: shipping.status,
						};
						$sale.applyShipping(data, $stateParams.id).then(
							function (response) {
								$scope.sale = response;
								$scope.sale.shipping_status = shipping.status;
								$scope.waiting = false;
							},
							function (error) {
								$scope.waiting = false;
							}
						);
					};

					$scope.shipping = {
						shipping_code: "",
					};

					$scope.confirm = function () {
						$sale
							.update(
								{
									id: sale.hashid,
								},
								$scope.shipping
							)
							.$promise.then(
								function (data) {
									$uibModalInstance.close();
									$scope.$parent.tableShippingParams.reload();
									ngToast.success({
										content:
											"Status modificado com sucesso",
										dismissOnTimeout: true,
										timeout: 5000,
									});
								},
								function (error) {
									ngToast.danger({
										content: "Erro inexperado",
										dismissOnTimeout: true,
										timeout: 5000,
									});
								}
							);
					};

					$scope.cancel = function () {
						$uibModalInstance.close();
					};
				},
			],
		});
	};

	$scope.sendToshipping = function (row) {
		$sale
			.update({ id: row.hashid }, { shipping_type: "delivering" })
			.$promise.then(
				function (data) {
					$scope.tableParams.reload();
					ngToast.success({
						content: "Venda enviada para Entregas!",
						dismissOnTimeout: true,
						timeout: 5000,
					});
				},
				function (error) {
					ngToast.danger({
						content: "Erro inexperado",
						dismissOnTimeout: true,
						timeout: 5000,
					});
				}
			);
	};

	var ids = [];
	$scope.results = [];
	$scope.goDelivering = function (row) {
		if (row) {
			var next = _.find($scope.results, function (item) {
				return item.hashid == [row.hashid];
			});
			if (next) {
				ngToast.danger({
					content: "Venda já adicionada!",
					dismissOnTimeout: true,
					timeout: 3000,
				});
				return;
			}
			ngToast.success({
				content: "Venda adicionada para separação!",
				dismissOnTimeout: true,
				timeout: 1000,
			});
			$scope.modal = true;
			$scope.results.push(row);
		}

		var data = [row.hashid];
		_.each(data, function (id) {
			ids.push(id);
		});
	};

	$scope.printDelivery = function () {
		$scope.printing = true;
		$http.post(api + "delivery_print", { data: ids }).then(
			function (response) {
				$scope.printing = false;
				$rootScope.print(response.data);
			},
			function (error) {
				ngToast.danger({
					content: "Sem dados para impressão!",
					dismissOnTimeout: true,
					timeout: 3000,
				});
				$scope.printing = false;
			}
		);
		$scope.clearDelivery();
	};

	$scope.clearDelivery = function () {
		$scope.modal = false;
		$scope.results = [];
		$scope.tableShippingParams.reload();
	};

	$scope.changeStatusDelivering = function () {
		var modalInstance = $uibModal.open({
			animation: true,
			templateUrl: "edit_shipping.modal.html",
			size: "md",
			scope: $scope,
			backdrop: "static",
			controller: [
				"$scope",
				"$uibModalInstance",
				"$client",
				"Upload",
				"$q",
				"ngToast",
				function (
					$scope,
					$uibModalInstance,
					$client,
					Upload,
					$q,
					ngToast
				) {
					$scope.cancel_text = "Cancelar";
					$scope.success_text = "OK";
					$scope.server = server;

					var array = $scope.results;

					$scope.confirm = function () {
						_.each(array, function (item) {
							item.shipping_status =
								$scope.shipping.shipping_status;
							item.id_service = item.hashid;

							$sale.post(item, item.id_service).then(
								function (data) {
									$scope.tableShippingParams.reload();
									$uibModalInstance.close();
									ngToast.success({
										content:
											"Status modificado com sucesso!",
										dismissOnTimeout: true,
										timeout: 5000,
									});
								},
								function (error) {
									ngToast.danger({
										content: "Erro inexperado",
										dismissOnTimeout: true,
										timeout: 5000,
									});
								}
							);
						});
					};

					$scope.cancel = function () {
						$uibModalInstance.close();
					};
				},
			],
		});
	};

	$scope.shipping_status = [
		{ id: "", title: "Todos" },
		{ id: "waiting", title: "Aguardando Envio" },
		{ id: "posted", title: "Postado" },
		{ id: "delivering", title: "Enviado" },
		{ id: "delivered", title: "Entregue" },
	];

	$scope.payment_status = [
		{ id: "", title: "Todos" },
		{ id: "opened", title: "Aberta" },
		{ id: "canceled", title: "Cancelada" },
		// {id:'succeeded', title:'Pago'}
	];

	$scope.clearPaymentLater = function () {
		$scope.newSale.payment_later = false;
	};

	$scope.changePayment = function () {
		if (
			$scope.newSale.payment_later &&
			$scope.newSale.status != "succeeded"
		)
			return;
		if ($scope.newSale.total <= 0 && $scope.newSale.reduction_value == 0) {
			ngToast.danger({
				content: "Adicione itens na venda primeiro",
				dismissOnTimeout: true,
				timeout: 5000,
			});
			return;
		}
		var modalInstance = $uibModal.open({
			animation: true,
			templateUrl: server + "/views/m/cashier/payments",
			size: "md",
			scope: $scope,
			backdrop: "static",
			controller: [
				"$scope",
				"$uibModalInstance",
				"_",
				"ngToast",
				function ($scope, $uibModalInstance, _, ngToast) {
					$scope.title = "Forma de pagamento";
					$scope.cancel_text = "Cancelar";
					$scope.success_text = "Selecionar";
					$scope.minClausule = moment().format("yyyy-MM-DD");
					$scope.newPayments = [];
					$scope.has_new_payment = false;

					$scope.company = $scope.$parent.company;

					$scope.remainder = function () {
						return _.reduce(
							$scope.newSale.payments,
							function (memo, row) {
								return parseFloat(memo) + parseFloat(row.value);
							},
							0
						);
					};

					$scope.startValues = function () {
						$scope.boletos = [];
						$scope.payments = $scope.$parent.newSale.payments;
						$scope.payment = {
							payment_method: null,
							payment_formated: "Outros",
							payment_description: "Outros",
							value:
								$scope.$parent.newSale.total -
								$scope.remainder(),
							charges: 1,
							payment_other_id: null,
						};
						$scope.totalPayment = $scope.remainder();
					};
					$scope.startValues();

					$scope.removePayment = function (index, bCredntials) {
						if (
							bCredntials &&
							$scope.$parent.newSale.hashid &&
							$scope.$parent.newSale.payment_later
						) {
							var modalInstance = $uibModal.open({
								animation: true,
								templateUrl: "confirm.modal.html",
								size: "sm",
								scope: $scope,
								controller: [
									"$state",
									"$scope",
									"$uibModalInstance",
									"$client",
									"$http",
									"ngToast",
									function (
										$state,
										$scope,
										$uibModalInstance,
										$client,
										$http,
										ngToast
									) {
										$scope.title =
											"Usuários com maiores permissões de desconto!";
										$scope.cancel_text = "Cancelar";
										$scope.success_text = "Confirmar";
										$scope.permissionsHeight = [];

										$users
											.query({
												permission_name:
													"cashier_delete",
												count: 100,
											})
											.$promise.then(function (response) {
												$scope.permissionsHeight =
													response.data;
											});

										$scope.pCredntials = true;
										$scope.credentials = {
											user_id: "",
											password: "",
										};

										$scope.confirm = function () {
											$users
												.confirm($scope.credentials)
												.then(
													function (response) {
														$scope.$parent.newSale.payments.splice(
															index,
															1
														);
														$scope.startValues();
														ngToast.success({
															content:
																"Pagamento removido",
															dismissOnTimeout: true,
															timeout: 1000,
														});
														$uibModalInstance.close(
															true
														);
													},
													function (error) {
														ngToast.danger({
															content:
																"Erro ao remover pagamento",
															dismissOnTimeout: true,
															timeout: 1000,
														});
													}
												);
										};

										$scope.cancel = function () {
											$uibModalInstance.dismiss("cancel");
										};
									},
								],
							});
						} else {
							$scope.$parent.newSale.payments.splice(index, 1);
							$scope.startValues();
						}
					};

					$scope.blockValue = function () {
						return !_.contains(
							[
								"pos",
								"pos_debit",
								"pos_credit",
								"credit_card",
								"money",
								"others",
								"pix",
							],
							$scope.payment.payment_method
						);
					};

					$scope.blockCharges = function () {
						return !_.contains(
							[
								"pos",
								"pos_credit",
								"credit_card",
								"boleto",
								"others",
								"link",
							],
							$scope.payment.payment_method
						);
					};

					$scope.selectPayment = function (
						payment,
						payment_formated = null
					) {
						if ($scope.payment.value < 0) {
							ngToast.danger({
								content:
									"Não é possivel inserir novas formas de pagamento",
								dismissOnTimeout: true,
								timeout: 5000,
							});
							return;
						}

						$scope.payment.amount_paid = $scope.payment.value;

						if (payment == "boleto") {
							if (!$scope.$parent.newSale.buyer) {
								ngToast.danger({
									content:
										"Boleto não disponível para consumidor",
									dismissOnTimeout: true,
									timeout: 5000,
								});
								return;
							}
							$http
								.get(
									api +
										"client/" +
										$scope.$parent.newSale.buyer.id +
										"/check_register"
								)
								.then(
									function (response) {
										$scope.payment.payment_method = payment;
										$scope.payment.payment_description =
											payment_formated;
										$scope.payment.payment_formated =
											payment_formated;
										$scope.changeCharges();
									},
									function (error) {
										ngToast.danger({
											content:
												"Boleto não disponível para este cliente",
											dismissOnTimeout: true,
											timeout: 5000,
										});
										return;
									}
								);
						} else {
							$scope.payment.payment_method = payment;
							$scope.payment.payment_formated = payment_formated;
							$scope.payment.payment_description =
								payment_formated;

							if ($scope.payment.payment_method == "money") {
								$rootScope.setFocusID("valueAmount_paid");
							}
							if ($scope.payment.payment_method == "pix") {
								$rootScope.setFocusID("valueAmount_paid");
							}
							if ($scope.payment.payment_method == "pos_credit") {
								$rootScope.setFocusID("chargesField");
							}
							if ($scope.payment.payment_method == "pos_debit") {
								$rootScope.setFocusID("valueMoney");
							}
							if ($scope.payment.payment_method == "others") {
								var modalInstance = $uibModal.open({
									animation: true,
									templateUrl: "selectItem.modal.html",
									size: "sm",
									scope: $scope,
									controller: [
										"$state",
										"$scope",
										"$uibModalInstance",
										"$client",
										"$http",
										"ngToast",
										function (
											$state,
											$scope,
											$uibModalInstance,
											$client,
											$http,
											ngToast
										) {
											$scope.title =
												"Outras formas de pagamento";
											$scope.descriptionSuggestionsList =
												[];
											$suggestion
												.query({
													type: "payment_other",
												})
												.$promise.then(function (
													response
												) {
													$scope.data = response;
												});

											$scope.selectRow = function (data) {
												if (data) {
													$scope.payment.payment_method =
														payment;
													$scope.payment.payment_formated =
														data.description;
													$scope.payment.payment_description =
														data.description;
													$scope.payment.payment_other_id =
														data.id;
												}
												console.log($scope.payment);
												$uibModalInstance.dismiss(
													"cancel"
												);
											};

											$scope.cancel = function () {
												$uibModalInstance.dismiss(
													"cancel"
												);
											};
										},
									],
								});
							}

							$scope.changeCharges();
						}
					};

					$scope.changeCharges = function () {
						$scope.boletos = [];
						if (
							$scope.payment.payment_method !== "boleto" &&
							$scope.payment.payment_method !== "others"
						)
							return;

						var date = moment().hour(0).add(1, "days");
						var sumValue = 0;
						var value = (
							$scope.payment.value / $scope.payment.charges
						).toFixed(2);

						for (var i = 0; i < $scope.payment.charges; i++) {
							$scope.boletos.push({
								payment_description:
									$scope.payment.payment_description +
									" " +
									(parseInt(i) + 1),
								value: value,
								boleto_expiration_date: date.toDate(),
							});
							date = moment(date).hour(0).add(1, "months");
							sumValue = $rootScope.sumFloat(sumValue, value);
						}

						if (sumValue > $scope.payment.value)
							$scope.boletos[0].value =
								$scope.boletos[0].value -
								(sumValue - $scope.payment.value).toFixed(2);

						if (sumValue < $scope.payment.value)
							$scope.boletos[0].value = $rootScope.sumFloat(
								$scope.boletos[0].value,
								($scope.payment.value - sumValue).toFixed(2)
							);
					};

					$scope.paymentLater = function () {
						$scope.$parent.newSale.payment_later = true;
						$uibModalInstance.close(true);
						return;
					};

					$scope.confirm = function () {
						if (
							!$scope.payment.payment_method ||
							$scope.payment.value <= 0 ||
							($scope.payment.charges < 1 &&
								!$scope.newSale.reduction_value)
						)
							return;

						$scope.payment.payment_description =
							$scope.payment.payment_formated;
						if (
							$scope.payment.payment_method == "boleto" ||
							$scope.payment.payment_method == "others"
						) {
							if (
								_.find($scope.boletos, function (obj) {
									return parseFloat(obj.value) < 0.01;
								})
							) {
								ngToast.danger({
									content:
										"O valor da parcela não pode ser igual a zero!",
									dismissOnTimeout: true,
									timeout: 5000,
								});
								return;
							}
							_.each($scope.boletos, function (boleto) {
								$scope.$parent.newSale.payments.push({
									payment_description:
										$scope.payment.payment_description,
									payment_formated:
										$scope.payment.payment_formated,
									payment_method:
										$scope.payment.payment_method,
									value: boleto.value,
									boleto_expiration_date:
										boleto.boleto_expiration_date,
									payment_other_id:
										$scope.payment.payment_other_id,
									charges: 1,
								});
							});
							$scope.startValues();
							if ($scope.payment.value == 0) {
								$uibModalInstance.close(true);
							}
							return;
						}

						if (
							($scope.payment.payment_method == "money" &&
								$scope.payment.amount_paid !=
									$scope.payment.value) ||
							($scope.payment.payment_method == "pix" &&
								$scope.payment.amount_paid !=
									$scope.payment.value)
						) {
							if (
								$scope.payment.amount_paid <
								$scope.payment.value
							) {
								$scope.payment.value =
									$scope.payment.amount_paid;
							}

							if (
								$scope.payment.amount_paid >
								$scope.payment.value
							) {
								$scope.payment.change_value =
									$scope.payment.amount_paid -
									$scope.payment.value;
								$scope.$parent.newSale.change =
									$scope.payment.change_value;
							}
						}

						if (
							($scope.payment.payment_method != "money" &&
								$scope.payment.amount_paid <
									$scope.payment.value) ||
							($scope.payment.payment_method != "pix" &&
								$scope.payment.amount_paid <
									$scope.payment.value)
						) {
							$scope.payment.value = $scope.payment.amount_paid;
							ngToast.danger({
								content:
									"Não é possivel fazer um pagamento maior que R$" +
									$scope.payment.value,
								dismissOnTimeout: true,
								timeout: 5000,
							});
							return;
						}

						if ($scope.$parent.newSale.payment_later == 1) {
							$scope.has_new_payment = true;
							$scope.newPayments.push($scope.payment);
							$scope.$parent.newSale.payments.push(
								$scope.payment
							);
							$scope.idx =
								$scope.$parent.newSale.payments.length -
								$scope.newPayments.length;
							$scope.startValues();
						} else {
							$scope.$parent.newSale.payments.push(
								$scope.payment
							);
							$scope.startValues();
						}

						if ($scope.payment.value == 0) {
							$uibModalInstance.close(true);
						}
					};

					$scope.cancel = function () {
						$uibModalInstance.dismiss("cancel");
					};
				},
			],
		});

		modalInstance.result.then(function (data) {
			if (data == true) {
				if ($scope.company.save_sale_automatic)
                    $scope.save(
                        false, 
                        $scope.newSale.status == 'succeeded' ? $scope.newSale.status : 'budget',
                        true,
                    );
			}
		});
	};

    var checkSale = function(bPayment = true){
        if($scope.newSale.total == 0 && $scope.newSale.reduction_value == 0){
            ngToast.danger({
                content: 'Adicione itens na venda primeiro',
                dismissOnTimeout: true,
                timeout: 1000
            });
            $scope.waiting = false;
            return false;
        }
        if(bPayment && $scope.newSale.payments.length == 0 && $scope.newSale.reduction_value > 0 && !$scope.newSale.payment_later && !$scope.newSale.reduction_value){
            ngToast.danger({
                content: 'Selecione a forma de pagamento primeiro',
                dismissOnTimeout: true,
                timeout: 1000
            });
            $scope.waiting = false;

            return false;
        }
        if($scope.newSale.payment_later && !$scope.newSale.buyer){
            ngToast.danger({
                content: 'Venda posterior necessita de um cliente',
                dismissOnTimeout: true,
                timeout: 1000
            });
            $scope.waiting = false;

            return false;
        }

        return true;
    }

    $scope.saveWithSeller = function(){
        if(!checkSale(false))
            return;

        if ($scope.company.change_seller && $scope.company.change_seller == 'confirm') {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'select_data.modal.html',
                size: 'md',
                scope: $scope,
                backdrop        : 'static',
                controller: ['$scope', '$uibModalInstance', '$user', 'Upload', '$q', '$company',
                function($scope, $uibModalInstance, $user, Upload, $q, $company) {

                    $scope.title = 'Confirmar vendedor?';
                    $scope.title_name = 'Nome';
                    $scope.success_text = 'Confirmar';

                    $scope.data = $scope.$parent.users;

                    $scope.select = function(id){
                        $scope.newSale.seller_id = id;
                        $uibModalInstance.close();
                        $scope.save(false, 'opened');
                    }

                }]
            });
        }
    }

    $scope.orcamentoWithSeller = function(){
        if(!checkSale(false))
            return;

        if ($scope.company.change_seller && $scope.company.change_seller == 'confirm') {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'select_data.modal.html',
                size: 'md',
                scope: $scope,
                backdrop        : 'static',
                controller: ['$scope', '$uibModalInstance', '$user', 'Upload', '$q', '$company',
                function($scope, $uibModalInstance, $user, Upload, $q, $company) {

                    $scope.title = 'Confirmar vendedor?';
                    $scope.title_name = 'Nome';
                    $scope.success_text = 'Confirmar';

                    $scope.data = $scope.$parent.users;

                    $scope.select = function(id){
                        $scope.newSale.seller_id = id;
                        $uibModalInstance.close();
                        $scope.save(false, 'budget');
                    }

                }]
            });
        }
    }

    $scope.save = function(sendShipping = false, status = null, stayOnScreen = false) {      
        if($scope.waiting)
            return;
        $scope.waiting = true;
        if(!checkSale(false))
            return;

        if (sendShipping) {
            $scope.newSale.has_delivery = 1;
        }
        if (status) {
            $scope.newSale.status = status;
        }

        if ( !$scope.newSale.call_number
            && $scope.company.cashier_identifier == 'required_without_client'
            && !$scope.newSale.buyer
            && ( $scope.newSale.status == 'opened' || $scope.newSale.status == 'budget' )) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'select.modal.html',
                size: 'sm',
                scope: $scope,
                backdrop: 'static',
                keyboard: false,
                controller: ['$state', '$scope', '$uibModalInstance',
                function( $state,$scope,$uibModalInstance ) {
                    setTimeout( function() {
                        $rootScope.setFocusID( 'cpfField' );
                    },300 );
                        
                    $scope.cancel_text = 'Não';
                    $scope.title = 'Identificador obrigatório';
                    $scope.input = {
                        type    : 'text',
                        value   : null
                    }

                    $scope.optionRight = {
                        label   : 'Continuar'
                    }
                        
                    $scope.confirm = function(){
                        if ( $scope.input.value && $scope.input.value.length > 0 ) {
                            $scope.newSale.call_number = $scope.input.value;
                            $scope.save( sendShipping,status,stayOnScreen );
                            $uibModalInstance.close();
                        } else {
                            $scope.newSale.call_number = 'Consumidor';
                            $scope.save( sendShipping,status,stayOnScreen );
                            $uibModalInstance.close();
                        }
                    }
                    
                    $scope.cancel = function() {
                        $scope.newSale.call_number = 'Consumidor';
                        $scope.save( sendShipping,status,stayOnScreen );
                        $uibModalInstance.close();
                    };
                }]
                
            });
            $scope.waiting = false;
            return false;

        }


        $scope.newSale.save = 'onlySave';
        $sale.save($scope.newSale).$promise
        .then(function(response){
            $scope.newSale = response;
            $scope.waiting = false;
            $scope.calcTotal(false);
            
            if (!stayOnScreen) {
                if (response.automatic_print) {
                    $scope.print($scope.newSale.hashid);
                }
                ngToast.success({
                    content: 'Venda salva com sucesso',
                    dismissOnTimeout: true,
                    timeout: 600
                });
                if($scope.company.cashier_identifier == 'required_without_client'){
                    $scope.resetNewSale('newSale');
                }else{
                    $scope.resetNewSale('list');
                };  
            }  
        }, function(error) {
            console.log(error);
            $scope.waiting = false;
            $scope.resetNewSale('newSale');
            ngToast.danger({
                content: error.data.message,
                dismissOnTimeout: true,
                timeout: 6500
            });
        });
    }

    $scope.openSale = function(row){
        if (row && row.payment_later == 1) {
            $scope.blockLabels = true;
            $scope.has_payment_later = true;
            $scope.newSale = row;
            $scope.container = 'newSale';
            $scope.calcTotal(false);
        }else{
            $scope.newSale = row;
            $scope.newSale.itemsSupplies = true;
            $scope.container = 'newSale';
            $scope.blockLabels = false;
            $scope.calcTotal(false);
        }
    }

    $scope.checkoutWithSellerParam = function(){
        if(!checkSale())
            return;

        if ( ( $scope.company.change_seller && $scope.company.change_seller == 'confirm' ) && !$scope.newSale.payment_later || ( $scope.company.change_seller && $scope.company.change_seller == 'confirm' && $scope.newSale.payment_later && !$scope.newSale.hashid) ) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'select_data.modal.html',
                size: 'md',
                scope: $scope,
                backdrop        : 'static',
                keyboard        : false,
                controller: ['$scope', '$uibModalInstance', '$user', 'Upload', '$q', '$company',
                function($scope, $uibModalInstance, $user, Upload, $q, $company) {

                    $scope.title = 'Confirmar vendedor?';
                    $scope.title_name = 'Nome';
                    $scope.success_text = 'Confirmar';

                    $scope.data = $scope.$parent.users;

                    $scope.select = function(id){
                        $scope.newSale.seller_id = id;
                        $uibModalInstance.close();
                        $scope.checkout();
                    }

                }]
            });
        }else{
            $scope.checkout();
        }
    }

    $scope.checkout = function() {
        $scope.closeX = false;
        $scope.waiting = true;
        if(!checkSale())
            return;
        
        var sumPayments = _.reduce($scope.newSale.payments, function(memo, row){ return parseFloat(memo) + parseFloat(row.value); }, 0);
        if( !$scope.newSale.payment_later && sumPayments.toFixed(2) != $scope.newSale.total.toFixed(2) ){
            ngToast.danger({
                content: 'Soma das formas de pagamento difere do total',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.waiting = false;
            return;
        }
        var bSave = true;
        _.each($scope.newSale.payments, function(payment){
            switch (payment.payment_method) {
                case 'money':
                    bSave = true;
                break;

                case 'pos':
                    bSave = true;
                break;

                case 'pos_credit':
                    bSave = true;
                break;

                case 'pos_debit':
                    bSave = true;
                break;

                case 'credit_card':
                    bSave = false;
                    var modalInstance = $uibModal.open({
                        animation      : true,
                        ariaLabelledBy : 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        templateUrl    : 'select_client_card.modal.html',
                        controller     : 'select_client_card',
                        controllerAs   : '$ctrl',
                        size           : 'md',
                        resolve        : {
                            client_id : $scope.newSale.client_id
                        }
                    });
                    modalInstance.result.then(function(data) {
                        if( data.card_token )
                            payment.card_token = data.card_token;
                        if( data.card ){
                            payment.card = {
                                'holder_name'       : data.card.name,
                                'expiration_month'  : data.card.expiration.substring(0,2),
                                'expiration_year'   : data.card.expiration.substring(2),
                                'card_number'       : data.card.number,
                                'security_code'     : data.card.cvc
                            }
                        }
                        $scope.saveSale();
                    });
                break;

                case 'link':
                    bSave = true;
                break;

                case 'boleto':
                    bSave = true;
                break;

                case 'others':
                    bSave = true;
                break;

                case 'pix':
                    bSave = true;
                break;

                case 'pix':
                    bSave = true;
                break;
            }
        });

        if( bSave ) {
            $scope.saveSale();
        }

        if($scope.newSale.payments.payment_method == 'Outros'){
            $scope.saveSale();
        }

    }

    $scope.printReport = function(){
        $scope.printing = true;
        var params = {
            print       : true,
            sumTotals   : true,
            user_id     : $scope.filterReport.user_id,
            start_date  : moment($scope.filterReport.date).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            end_date    : moment($scope.filterReport.date).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        }

        $sale.report(params).then(function(response){
            $scope.waiting = false;
            $rootScope.print(response);

        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });

    }

    $scope.mailReport = function(){
        $scope.printing = true;
        var params = {
            print       : true,
            mail        : true,
            sumTotals   : true,
            user_id     : $scope.filterReport.user_id,
            day         : $scope.filterReport.day,
            start_date  : $scope.filterReport.day ? moment($scope.today).date($scope.filterReport.day).startOf('day').format('YYYY-MM-DD HH:mm:ss') : moment($scope.today).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
            end_date    : $scope.filterReport.day ? moment($scope.today).date($scope.filterReport.day).endOf('day').format('YYYY-MM-DD HH:mm:ss') : moment($scope.today).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        }

        $sale.report(params).then(function(response){
            $scope.reportData = response;
            $scope.waiting = false;

            $scope.paymentsChar.labels  = _.pluck($scope.reportData.payments, 'method_formated');
            $scope.paymentsChar.data    = _.pluck($scope.reportData.payments, 'total');

            $scope.statusChar.labels    = _.pluck($scope.reportData.status, 'status_formated');
            $scope.statusChar.data      = _.pluck($scope.reportData.status, 'total');

            ngToast.success({
                content: 'Email enviado com sucesso',
                dismissOnTimeout: true,
                timeout: 2000
            });
        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });

    }

    $scope.print = function(row){
        
        if ($scope.company.password_print) {
            var data = { permission_name : 'cashier_print_sale'};
        
            $users.checkPermissions(data).then(function(response){
                if ($scope.company.type_print == 'which_print_question') {
                    $scope.printWhitchPrintQuestion(row);
                }else{
                    var params = {
                        type_print: null
                    }
                    $scope.printing = true;
                    $sale.printSale(params, row).then(function(response){
                        $scope.printing = false;
                        $rootScope.print(response);
                        if($scope.company.category_print == 1){
                            setTimeout(function () {
                                window.onfocus = $scope.printCategory(row);
                            }, 100);
                        }
                    }, function(error) {
                        ngToast.danger({
                            content: 'Sem dados para impressão!',
                            dismissOnTimeout: true,
                            timeout: 3000
                        });
                        $scope.printing = false;
                    });
                }
            },function(error){
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: 'confirm.modal.html',
                    size: 'sm',
                    scope: $scope,
                    controller: ['$state', '$scope', '$uibModalInstance', '$client', '$http', 'ngToast', '$user_role',
                    function($state, $scope, $uibModalInstance, $client, $http, ngToast, $user_role) {
        
                        $scope.title = 'Usuários com permissão impressão de venda!';
                        $scope.cancel_text = 'Cancelar';
                        $scope.success_text = 'Confirmar';
                        $scope.permissionsHeight = [];

                        $scope.filter = {
                            count : 10,
                            permission_name : 'cashier_print_sale'
                        };

                        $users.permissions($scope.filter).then(function(response){
                            $scope.permissionsHeight = response;
                        });
        
                        $scope.pCredntials  = true;
                        $scope.credentials = {
                            user_id     : '',
                            password    : ''
                        }
        
                        $scope.confirm = function() {
                            $users.confirm($scope.credentials).then(function(response){
                                if ($scope.company.type_print == 'which_print_question') {
                                    $scope.printWhitchPrintQuestion(row);
                                }else{
                                    var params = {
                                        type_print: null
                                    }
                                    $scope.printing = true;
                                    $sale.printSale(params, row).then(function(response){
                                        $scope.printing = false;
                                        $rootScope.print(response);
                                        if($scope.company.category_print == 1){
                                        setTimeout(function () {
                                            window.onfocus = $scope.printCategory(row);
                                        }, 100);
                                        }
                                    }, function(error) {
                                        ngToast.danger({
                                            content: 'Sem dados para impressão!',
                                            dismissOnTimeout: true,
                                            timeout: 3000
                                        });
                                        $scope.printing = false;
                                    });
                                }
                                $uibModalInstance.close();
                            }, function(error) {
                                ngToast.danger({
                                    content: 'Erro na impressão!',
                                    dismissOnTimeout: true,
                                    timeout: 1000
                                });
                            });
                            
                        }

                        $scope.cancel = function() {
                            $uibModalInstance.dismiss('cancel');
                        };
        
                    }]
                });
            });     
            
        } else {
            if ($scope.company.type_print == 'which_print_question') {
                $scope.printWhitchPrintQuestion(row);
            }else{
                var params = {
                    type_print: null
                }
                $scope.printing = true;
                $sale.printSale(params, row).then(function(response){
                    $scope.printing = false;
                    $rootScope.print(response);
                    if($scope.company.category_print == 1){
                      setTimeout(function () {
                          window.onfocus = $scope.printCategory(row);
                      }, 100);
                    }
                }, function(error) {
                    ngToast.danger({
                        content: 'Sem dados para impressão!',
                        dismissOnTimeout: true,
                        timeout: 3000
                    });
                    $scope.printing = false;
                });
    
            }
        }

    }

    $scope.printWhitchPrintQuestion = function(row){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'select_data.modal.html',
            size: 'md',
            scope: $scope,
            backdrop        : 'static',
            controller: ['$scope', '$uibModalInstance', '$user', 'Upload', '$q', '$company',
            function($scope, $uibModalInstance, $user, Upload, $q, $company) {

                $scope.title = 'Confirme o modelo de impressão!';
                $scope.title_name = 'Nome';
                $scope.success_text = 'Confirmar';

                $scope.data = [
                    {id:'normal', name:'Normal'},
                    {id:'bank_corresponding', name:'Correspondente bancário'},
                    {id:'thermal', name:'Térmica'},
                    {id:'detailed', name:'Detalhada'},
                    {id:'half_paper', name:'Meia Folha'}
                ];

                $scope.select = function(id){
                    $uibModalInstance.close(id);
                }

            }]
        });
        modalInstance.result.then(function(data) {
            var params = {
                type_print: data ? data : null
            }
            $scope.printing = true;
            $sale.printSale(params, row).then(function(response){
                $scope.printing = false;
                setTimeout($rootScope.print(response), 2000);
                if($scope.company.category_print == 1){
                  setTimeout(function () {
                      window.onfocus = $scope.printCategory(row);
                  }, 100);
                }
            }, function(error) {
                ngToast.danger({
                    content: 'Sem dados para impressão!',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
                $scope.printing = false;
            });
        });
    }

    $scope.printCategory = function(row){
        window.onfocus = '';
        $scope.printing = true;
        $http.get(api+'sale/'+row+'/print_item_category').then(function(response){
            $scope.printing = false;
            $rootScope.print(response.data);
        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });
    }

    $scope.printShipping = function(row){
        $scope.printing = true;
        $http.get(api+'sale/'+row+'/shipping_print').then(function(response){
            $scope.printing = false;
            $rootScope.print(response.data);
        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });
    }

    $scope.saveSale = function(){
        var devolutionsParam = $scope.newSale.devolution_items;

        $sale.save($scope.newSale).$promise.then(async function(response){
            $scope.newSale = response;
            $scope.waiting = false;

            if( $scope.newSale.payments.length > 0 && $scope.newSale.payments[0].payment_method == 'link' ){
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: 'show_paymentLink.modal.html',
                    size: 'sm',
                    scope: $scope,
                    backdrop        : 'static',
                    controller: ['$scope', '$uibModalInstance', '$client', 'Upload', '$q',
                    function($scope, $uibModalInstance, $client, Upload, $q) {

                        $scope.title = 'Envie esse link para seu cliente';
                        $scope.link = server + '/checkout/' + response.hashid;
                        $scope.cancel_text = 'Cancelar';
                        $scope.success_text = 'OK';

                        $scope.copy = function() {
                            var copyText = document.getElementById("linkSale");

                            copyText.select();
                            copyText.setSelectionRange(0, 99999);

                            document.execCommand("copy");
                        }

                        $scope.confirm = function() {
                            $uibModalInstance.close();
                        }

                        $scope.cancel = function() {
                            $uibModalInstance.close();
                        };

                    }]
                });
            }
            if( $scope.newSale.payments.length > 0 && $scope.newSale.payments[0].payment_method == 'boleto' ){
                $scope.viewPayments($scope.newSale.hashid);
            }
            if (devolutionsParam) {
                devolutionsParam.items.forEach(e => {
                    if (e.sale.invoice && e.sale.invoice.infNFe && e.sale.invoice.status == 'authorized') {
                        $scope.devolution_params = true;
                    }
                });
                if ($scope.devolution_params == true) {
                    $scope.makeDevolutionInvoice(devolutionsParam);
                }
            }

            if ( !$scope.newSale.invoice ) {
                await $scope.makeInvoice(response.hashid); 
            }

            if( response.automatic_print ){
                $scope.print(response.hashid);
            }

            // $scope.resetNewSale();
            $scope.resetNewSaleWithUserId();

        }, function(error){
            $scope.resetNewSale('newSale');
            $scope.waiting = false;
            ngToast.danger({
                content: error.data.message,
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
        $rootScope.setFocusID('codFinder');
    }

    $scope.resetNewSaleWithUserId = function() {
        $scope.lastSale = $scope.newSale;

        $scope.resetNewSale('newSale');

        $scope.newSale.seller_id = $scope.lastSale.seller_id;
        $scope.newSale.user_id  = $scope.lastSale.user_id;

    }

    $scope.canceleSale = function(id, bCredntials = true){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$client', function($state, $scope, $uibModalInstance, $client) {

                $scope.title = 'Essa operação não pode ser desfeita. Tem certeza?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.bCredntials  = bCredntials;
                $scope.bReason      = true;
                $scope.credentials ={
                    reason      : '',
                    user_id     : '',
                    password    : ''
                }

                $scope.confirm = function() {
                    $uibModalInstance.close($scope.credentials);
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function(data) {
            $scope.waiting = true;
            $sale.cancel(id, data).then(function(response){
                $scope.waiting = false;
                ngToast.success({
                    content: 'Venda cancelada',
                    dismissOnTimeout: true,
                    timeout: 5000
                });
                $scope.tableParams.reload();
            }, function(error){
                $scope.waiting = false;
                ngToast.danger({
                    content: 'Error ao deletar venda',
                    dismissOnTimeout: true,
                    timeout: 5000
                });
            });
        });
    }

    $scope.showInvoice = function(invoiceId){
        $invoice.get({id: invoiceId}).$promise.then(function(response){
            var invoice = response;
            if( invoice.status != 'authorized' ){
                var modalInstance = $uibModal.open({
                    animation      : true,
                    ariaLabelledBy : 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl    : server+"/views/m/invoice/show",
                    controller     : 'invoice.show',
                    size           : 'lg',  
                    resolve        : {
                        invoice_id     : invoice.id,
                        showInModal    : true
                    }
                });
                modalInstance.result.then(function() {
                    $scope.tableParams.reload();
                    $uibModalInstance.close();
                });
            } else {
                if (invoice.mod == '0'){
                    $invoice.printNFSe(invoice.id).then(function(response){
                        $rootScope.print(response); 
                    });
                } else {
                    $invoice.print(invoice.id).then(function(response){
                        var file = new Blob([response.data], {type: 'application/pdf'});
                        var fileURL = URL.createObjectURL(file);
        
                        // Create a link element on body
                        // var link = angular.element('<a href="' + fileURL + '" target="_blank"></a>');
                        var link = angular.element('<a href="javascript: w=window.open(\''+fileURL+'\'); setTimeout(function () { w.print();w.onfocus=function(){ w.close();} }, 10);"></a>');
        
                        angular.element(document.body).append(link);
                        link[0].click();
                        link.remove();
                    });
                }
            }
        });
        
    }

    $scope.makeInvoice = async function(id){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'select.modal.html',
            size: 'sm',
            scope: $scope,
            backdrop        : 'static',
            keyboard        : false,
            controller: ['$state', '$scope', '$uibModalInstance',
            function($state, $scope, $uibModalInstance) {

                $scope.cancel_text = 'Não';

                $scope.confirm = function(data) {
                    if(data == 65 && !$scope.input){
                        $scope.input = {
                            label   : 'Deseja incluir CPF na nota?',
                            type    : 'text',
                            mask    : 'ui-br-cpfcnpj-mask',
                            value   : null
                        }
                        $scope.optionRight.label = 'Continuar';
                        return;
                    }
                    data = {
                        mod : data,
                        cpf : $scope.input ? $scope.input.value : null
                    }
                    $uibModalInstance.close(data);
                }

                if($scope.$parent.company.default_nfce_at_cashier){
                    $scope.optionRight = {
                        label   : 'NFCe',
                        value   : 65
                    }
                    $scope.confirm(65);
                    $rootScope.setFocusID('cpfField');
                }else{
                    $scope.title = 'Selecione o tipo de nota';
                    $scope.optionLeft = {
                        label   : 'NFe | NFSe',
                        value   : 55
                    }
                    $scope.optionRight = {
                        label   : 'NFCe',
                        value   : 65
                    }
                }
                document.addEventListener('keydown', function(event){
                    if(event.key === "Escape"){
                        $scope.cancel();
                    }
                });
                $scope.cancel = function() {
                    $uibModalInstance.close(null);
                };

            }]


        });

        await modalInstance.result.then(function(data) {
            if(!data) 
                return false;
            if( $scope.waiting )
                return;
            $scope.waiting = true;
            var params = {
                sale_id     : id,
                mod         : data.mod,
                dest_cpf    : data.cpf

            }
            return $http.post(api+'invoice/store_sale', params).then(function(response){
                var invoice = response.data;
                $invoice.send(invoice.id, {movement: false}).then(function(response){
                    $scope.waiting = false;
                    if (invoice.mod == '0') {
                        $invoice.printNFSe(invoice.id).then(function(response){
                            $rootScope.print(response); 
                        });
                    } else {
                        $invoice.print(invoice.id).then(function(print){
                            var file = new Blob([print.data], {type: 'application/pdf'});
                            var fileURL = URL.createObjectURL(file);

                            var link = angular.element('<a href="javascript: w=window.open(\''+fileURL+'\'); setTimeout(function () { w.print();w.onfocus=function(){ w.close();} }, 10);"></a>');
                            angular.element(document.body).append(link);
                            link[0].click();
                            link.remove();
                        });
                    }
                    $scope.tableParams.reload();
                    // $uibModalInstance.close();
                }, function(error){
                    $scope.waiting = false;
                    ngToast.danger({
                        content: error.data.message,
                        dismissOnTimeout    : false,
                        dismissButton       : true,
                    });
                    $scope.tableParams.reload();
                    // $uibModalInstance.dismiss('cancel');
                });
            }, function(error) {
                $scope.waiting = false;
                ngToast.danger({
                    content: error.data.message,
                    dismissOnTimeout    : false,
                    dismissButton       : true,
                });
                $scope.tableParams.reload();
                // $uibModalInstance.dismiss('cancel');
            });
        });
    }

    $scope.makeDevolutionInvoice = function(data){

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'selectAndConfirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance',
            function($state, $scope, $uibModalInstance) {

                $scope.title = 'Nota de Devolução da troca!';
                $scope.cancel_text = 'Gerar depois';
                $scope.label = 'Deseja gerar nota de devolução?';
                $scope.confirm_text = 'Gerar';
                $scope.waiting = false;

                var services_ids = [];
                _.each(data.items, function(item){ return services_ids.push({
                    id: item.service_id,
                    qtd: item.quantity
                }); });
                $scope.paramsInvoiceDevolution.items = services_ids;
                $scope.paramsInvoiceDevolution.reduction_value = data.reduction_value;
                var getRef = _.each(data.items, function(item){ return $scope.paramsInvoiceDevolution.refNFe = item.sale.invoice.infNFe; });

                $scope.confirm = function() {
                    if( $scope.waiting )
                        return;
                    $scope.waiting = true;
                    $invoice.storeDevolution($scope.paramsInvoiceDevolution).then(function(response){
                        var invoice = response;
                        $invoice.send(invoice.id, {movement: false}).then(function(response){
                            ngToast.success({
                                content: 'Nota de devolução gerada com sucesso!',
                                dismissOnTimeout: true,
                                timeout: 5000
                            });
                        }, function(error){
                            ngToast.danger({
                                content: error.data.message, //'Erro ao enviar sefaz!',
                                dismissOnTimeout: true,
                                timeout: 5000
                            });
                            $uibModalInstance.dismiss('cancel');
                            $scope.waiting = false;
                        });
                        
                    }, function(error){
                        $scope.waiting = false;
                        ngToast.danger({
                            content: error.data.message, //'Erro ao enviar sefaz!',
                            dismissOnTimeout: true,
                            timeout: 5000
                        });
                        $uibModalInstance.dismiss('cancel');
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]


        });
    }

    $scope.showSendShipping = function(){
        $sale.query({month: $scope.today.toDate()}).$promise.then(function(response){
            sales = response.data;
        });
        $scope.showShipping = {
            sale : sales
        }
    }

    $scope.getShipping = function(){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'select_data.modal.html',
            size: 'md',
            scope: $scope,
            backdrop        : 'static',
            controller: ['$scope', '$uibModalInstance', '$user', 'Upload', '$q', '$company',
            function($scope, $uibModalInstance, $user, Upload, $q, $company) {

                $scope.title = 'Confirmar frete?';
                $scope.title_name = 'Nome';
                $scope.success_text = 'Confirmar';

                $company.get({id: $localStorage.company.id}).$promise.then(function(response){
                    $scope.data = response.company_shipments;
                });

                $scope.select = function(id){
                    var shipping = _.find($scope.data, function(row){ return row.id == id;});
                    $uibModalInstance.close(shipping);
                }

            }]
        });
        modalInstance.result.then(function(data) {
            $scope.newSale.shipping_value = data.value;
            $scope.payments = [];
            $scope.calcTotal();
        });
        
    }

    $scope.removeShipping = function(){
        $scope.newSale.shipping_value = 0;
        $scope.newSale.has_shipping = false;
        _.each($scope.newSale.items, function(row){
            row.shipping_value = 0;
        })
        $scope.calcTotal();
    }

    $scope.loadReport = function(typeForTo = null){
        $scope.waiting = true;
        $scope.searched = true;
        if (typeForTo === null) {
            $scope.paymentsChar = {
                labels  : [],
                data    : []
    
            };
    
            $scope.userChart = {
                labels  : [],
                data    : []
            };
    
            $scope.statusChar   = {
                labels  : [],
                data    : []
            };
    
    
            $scope.colors = {
                colors : ['#04c421', '#f50202', '#0471c4', '#4fc9db', '#797f80', '#e8bf56']
            };
        }

        $scope.container = 'report';
        var params = {
            print       : false,
            start_date  : moment($scope.filterReport.date).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            end_date    : moment($scope.filterReport.date).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        }

        if( $scope.filterReport.user_id )
            params.user_id = $scope.filterReport.user_id;

        switch (typeForTo) {
            case 'totalStatus':
                if ($scope.totalPaidShow) {
                    params.totalPaid = true;
                }
                if ($scope.totalWithdrawalShow) {
                    params.totalWithdrawal = true;
                }
                if ($scope.totalEntryShow) {
                    params.totalEntry = true;
                }
                if ($scope.saleInMoneyShow) {
                    params.totalWithdrawal = true;
                    params.totalEntry = true;
                    params.saleInMoney = true;
                }
                $scope.totalStatusShow = true;
                params.totalStatus = true;
                break;
            case 'totalPaid':
                if ($scope.totalWithdrawalShow) {
                    params.totalWithdrawal = true;
                }
                if ($scope.totalEntryShow) {
                    params.totalEntry = true;
                }
                if ($scope.saleInMoneyShow) {
                    params.totalWithdrawal = true;
                    params.totalEntry = true;
                    params.saleInMoney = true;
                }
                $scope.totalPaidShow = true;
                params.totalStatus = true;
                params.totalPaid = true;
                break;
            case 'totalEntry':
                if ($scope.totalStatusShow) {
                    params.totalStatus = true;
                }
                if ($scope.totalPaidShow) {
                    params.totalPaid = true;
                }
                if ($scope.totalWithdrawalShow) {
                    params.totalWithdrawal = true;
                }
                if ($scope.saleInMoneyShow) {
                    params.totalWithdrawal = true;
                    params.saleInMoney = true;
                }
                $scope.totalEntryShow = true;
                params.totalEntry = true;
                break;
            case 'totalWithdrawal':
                if ($scope.totalStatusShow) {
                    params.totalStatus = true;
                }
                if ($scope.totalPaidShow) {
                    params.totalPaid = true;
                }
                if ($scope.totalEntryShow) {
                    params.totalEntry = true;
                }
                if ($scope.saleInMoneyShow) {
                    params.totalEntry = true;
                    params.saleInMoney = true;
                }
                $scope.totalWithdrawalShow = true;
                params.totalWithdrawal = true;
                break;
            case 'saleInMoney':
                if ($scope.totalStatusShow) {
                    params.totalStatus = true;
                }
                if ($scope.totalPaidShow) {
                    params.totalPaid = true;
                }
                if ($scope.allShow) {
                    params.totalStatus = true;
                    params.totalPaid = true;
                }
                $scope.saleInMoneyShow = true;
                params.totalEntry = true;
                params.saleInMoney = true;
                params.totalWithdrawal = true;
                break;
            case 'allTotals':
                $scope.allShow = true;
                params.totalEntry = true;
                params.totalStatus = true;
                params.totalPaid = true;
                params.totalWithdrawal = true;
                break;
        
            default:
                $scope.allShow                 = false;
                $scope.totalPaidShow           = false;
                $scope.totalWithdrawalShow     = false;
                $scope.totalEntryShow          = false;
                $scope.saleInMoneyShow         = false;
                $scope.totalStatusShow         = false;
                break;
        }
        
        $sale.report(params).then(function(response){
            $scope.reportData = response;
            if (typeForTo === null) {
                $scope.paymentsChar.labels  = _.pluck($scope.reportData.payments, 'method_formated');
                $scope.paymentsChar.data    = _.pluck($scope.reportData.payments, 'total');
    
                $scope.userChart.labels  = _.pluck($scope.reportData.users, 'name');
                $scope.userChart.data    = _.pluck($scope.reportData.users, 'total');
    
                $scope.statusChar.labels    = _.pluck($scope.reportData.status, 'status_formated');
                $scope.statusChar.data      = _.pluck($scope.reportData.status, 'total');
    
                _.each($scope.reportData.payments, function(payment){
                    switch (payment.payment_method) {
                        case 'money':
                            $scope.reportData.totalMoney = $rootScope.sumFloat(payment.total, $scope.reportData.totalEntry) - $scope.reportData.totalWithdrawal;
                        break;
                    }
                });
    
                $financial_entry.paymentFinancial(params).then(function(response){
                    $scope.reportDataFinancial = response;
                    
                    $scope.financialPayments = {
                        totals  : []
                    };
        
                    $scope.financialPaymentsChar = {
                        labels  : [],
                        data    : []
            
                    };
        
                    $scope.payments = [
                        {title:'money'},
                        {title:'credit_card'},
                        {title:'pos_debit'},
                        {title:'boleto'},
                        {title:'link'},
                        {title:'pix'},
                        {title:'payment_later'},
                        {title:'other'},
                        {title:'in_cash'},
                    ];
                    
                    _.each($scope.payments, function(payment){
                        $scope.paymentTotal = _.reduce(_.filter($scope.reportDataFinancial.data, (row) => {
                            return row.payment.payment_method == payment.title;
                        }), function(memo, row){ return memo + (parseFloat(row.value))}, 0);
                        if($scope.paymentTotal > 0){
                            $scope.financialPayments.totals.push({'label' : payment.payment.method_formated, 'total' : $scope.paymentTotal});
                        }
                    })
                    
                    $scope.financialPaymentsChar.labels  = _.pluck($scope.financialPayments.totals, 'label');
                    $scope.financialPaymentsChar.data    = _.pluck($scope.financialPayments.totals, 'total');
                    $scope.waiting = false;
        
                }), function(error){
                    $scope.waiting = false;
                };
            }else{
                $scope.waiting = false;
            }
        }, function(error){
            $scope.waiting = false;
        });
    }

    $scope.loadMovements = function(){
        var params = {
            day         : $scope.today.date(),
            start_date  : $scope.filterMovement.day ? moment($scope.today).date($scope.filterMovement.day).startOf('day').format('YYYY-MM-DD HH:mm:ss') : moment($scope.today).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
            end_date    : $scope.filterMovement.day ? moment($scope.today).date($scope.filterMovement.day).endOf('day').format('YYYY-MM-DD HH:mm:ss') : moment($scope.today).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
            start_day_month : moment($scope.today).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
            end_day_month : moment($scope.today).endOf('month').format('YYYY-MM-DD HH:mm:ss')
        }
        $cashier_movement.query(params).$promise.then(function(response){
            $scope.movements = response.data;
            $scope.totalWithdrawal = response.totalWithdrawal;
            $scope.totalEntry = response.totalEntry;
            movements = response.movementsMonth;

            $scope.createDaysOfMonthMovement();
        });
    }

    $scope.addNewMovement = function(){
        $scope.newMovement = true;
        $scope.movement    = {};
        $rootScope.setFocusID('new_movement_description');
    }

    $scope.movementType = [
        {
            value: 'entry',
            label: 'Entrada'
        },
        {
            value: 'withdrawal',
            label: 'Retirada'
        }
    ];

    $scope.cancelNewMovement = function(){
        $scope.movement = {};
        $scope.newMovement = false;
    }

    $scope.addMovement = function(){
        $scope.newMovement = false;
        $scope.waiting = true;
        $cashier_movement.post($scope.movement).$promise.then(function(response){
            $scope.movements = response.data;
            $scope.loadMovements();
            $scope.waiting = false;
        });
    }

    $scope.noteEdit = function(){
        $scope.note = !$scope.note;
    }

    $scope.showReport = function(){
        return $scope.reportData.payments ? $scope.reportData.payments.length > 0 : false || $scope.reportData.totalEntry > 0 || $scope.reportData.totalWithdrawal > 0 || $scope.reportData.totalStatus > 0;
    }

    $scope.toogleShowPaymentDetailPaid = function(){
        $scope.showPaymentDetailPaid = !$scope.showPaymentDetailPaid;
    }

    $scope.toogleShowPaymentDetailClosed = function(){
        $scope.showPaymentDetailClosed = !$scope.showPaymentDetailClosed;
    }

    $scope.showTabReport = function(tab){
        $scope.paymentMethodTab = false;
        $scope.usersTab = false;
        $scope.contractTab = false;
        switch (tab){
            case 'paymentMethod':
                $scope.paymentMethodTab = true;   
            break;
            case 'users':
                $scope.usersTab = true;   
            break;
            case 'contract':
                $scope.contractTab = true;   
            break;

            default:
                $scope.paymentMethodTab = true; 
        }
    }

    $scope.loadTotalForTo = function(forTo = null){
        $scope.loadReport(forTo)
    }

}]);

angular.module('cashier').
controller('voucher.list', ['$scope', '$http', '$auth', '$q', '$uibModal', '$sale', 'ngToast', 'NgTableParams', '$invoice', '$service', '$rootScope', '$localStorage', '$users', '$sale_item',
function($scope, $http, $auth, $q, $uibModal, $sale, ngToast, NgTableParams, $invoice, $service, $rootScope, $localStorage, $users , $sale_item) {

    var voucher = [];

    $scope.codFinder    = '';
    $scope.today        = new moment();
    $scope.real_today   = new moment();
    $scope.waiting      = false;
    $scope.today_opened = {
        opened: false
    }
    $scope.totals       = {
        opened      : { count : 0, value : 0 },
        succeeded   : { count : 0, value : 0 },
        failed      : { count : 0, value : 0 }
    }
    $scope.filter       = {
        day         : $scope.today.date()
    };
    $scope.filterReport = {
        day         : $scope.today.date()
    };
    $scope.filterMovement = {
        day         : $scope.today.date()
    };

    $scope.tableVoucherParams = new NgTableParams({
        page: 1,
        count: Infinity,
        filter: {'service.type' : 'E'}
        }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $sale_item.query(params.url()).$promise.then(function(data) {
                $scope.vouchers = data.data;
                $scope.vouchers.qtd = data.qtd;
                $scope.vouchers.total = data.total;

                return data.data;
            });
        }
    });

}]);

angular.module('cashier').
controller('checkout', ['$scope', '$http', '$auth', '$q', '$uibModal', '$sale', 'ngToast', 'NgTableParams', 'WizardHandler', '$sale', '$stateParams', '$localStorage', '$countryState', '$city', '$client', '$sce', '$state',
function($scope, $http, $auth, $q, $uibModal, $sale, ngToast, NgTableParams, WizardHandler, $sale, $stateParams, $localStorage, $countryState, $city, $client, $sce, $state) {

    $scope.company      = company;

    $scope.acceptTerms  = false;
    $scope.cards        = [];
    $scope.newCard      = false;
    $scope.waiting      = false;
    $scope.client       = {
        cpf_cnpj : ''
    };

    $scope.placeholders = {
        number: '•••• •••• •••• ••••',
        name: 'Nome do titular'
    }

    $scope.messages = {
        validDate: 'válido\naté',
        monthYear: 'mês/ano',
    }

    $scope.card = {
        card_number: '',
        holder_name: '',
        expiration: '',
        security_code: ''
    }

    $scope.filterTotal = function(data){
        return _.reduce(data, function(memo, row){ return memo + row.total; }, 0);
    }

    $scope.getSale = function(){
        $sale.get({id: $stateParams.id}).$promise.then(function(response){
            $scope.sale = response;
            $localStorage.cart = $scope.sale;
            if( $localStorage.cart.items.length < 1 ){
                location.href = '/';
            }
        });
    }
    $scope.getSale();

    $scope.editData = function(){
        WizardHandler.wizard().goTo("Cadastro");
    }

    $scope.getShippings = function(){
        if( $scope.sale && $scope.sale.has_shipping || $scope.has_free_shipping){
            $scope.waiting = true;
            $sale.calcShipping($stateParams.id).then(function(response){
                $scope.shippings = response[0];
                $scope.shipments = response[1].company_shipments;
                $scope.waiting = false;
            }, function(error){
                $scope.shippings = [];
                $scope.waiting = false;
            });
        }
    }

    $scope.changeShipping = function(shipping){
        if($scope.waiting)
            return;

        $scope.waiting = true;
        var data = {
            'shipping_type'     : shipping.name,
            'shipping_value'    : shipping.price,
            'shipping_status'   : shipping.status
        }
        $sale.applyShipping(data, $stateParams.id).then(function(response){
            $scope.sale = response;
            $scope.sale.shipping_type = shipping.name;
            $scope.sale.shipping_status = shipping.status;
            $scope.waiting = false;
        }, function(error){
            $scope.waiting = false;
        });
    }

    $scope.NextWizard = function(){
        if( $scope.sale.buyer ){
            $scope.queryCities();
            if( $scope.sale.buyer.completed_registration == 1 ){
                WizardHandler.wizard().goTo("Pagamento");
                $scope.getShippings();
            } else {
                WizardHandler.wizard().goTo("Cadastro");
            }
            return;
        }
        WizardHandler.wizard().next();
    };

    $scope.searchClient = function(invalid){

        if(invalid)
            return;

        $sale.checkClient({cpf_cnpj : $scope.client.cpf_cnpj}, $stateParams.id).then(function(response){
            $scope.sale.buyer = response;
            if( $scope.sale.buyer.completed_registration == 1 ){
                WizardHandler.wizard().goTo("Pagamento");
                $scope.getShippings();
            } else {
                WizardHandler.wizard().next();
            }
        }, function(error){
            $scope.sale.buyer = $scope.client;
            WizardHandler.wizard().next();
            $scope.getShippings();
        });
    }


    $scope.saveData = function(form) {
        if(form.$invalid){
            ngToast.danger({
                content: 'Preencha todos os campos corretamente',
                dismissOnTimeout: true,
                timeout: 5000
            });
            return;
        }
        $scope.waiting = true;
        $sale.updateBuyer($scope.sale.buyer, $stateParams.id).then(function(response){
            $scope.sale.buyer = response;
            WizardHandler.wizard().goTo("Pagamento");
            $scope.getShippings();
            $scope.waiting = false;
        }, function(error){
            $scope.waiting = false;
            ngToast.danger({
                content: 'Erro interno',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
    }

    $scope.changeCharges = function() {
        var tax = _.where($scope.sale.taxes, {installments: $scope.sale.payments[0].charges})[0];
        $scope.sale.amount = tax.total;
        $scope.sale.payments[0].amount = tax.total;
    }

    $scope.addCard = function() {
        $scope.newCard = true;
    }

    $scope.createCard = function() {
        $scope.waiting = true;
        $scope.is_card_saving = true;
        var exp = $scope.card.expiration.split('/');
        var exp = exp[0].trim() + exp[1].trim();

        $scope.card.expiration_month = exp.substring(0,2);
        $scope.card.expiration_year  = exp.substring(2);

        $client.storeCards($scope.sale.buyer.id, $scope.card).then(function(response){
            $scope.cards.push(response);
            $scope.sale.payments[0].client_card_id = response.id;
            $scope.is_card_saving = false;
            $scope.newCard = false;
            $scope.waiting = false;
        }, function(error){
            $scope.waiting = false;
            ngToast.danger({
                content: 'Erro ao adicionar cartão',
                dismissOnTimeout: true,
                timeout: 5000
            });
            $scope.is_card_saving = false;
        });
    }

    $scope.checkout = function() {
        $scope.waiting = true;
        $sale.checkout($scope.sale, $stateParams.id).then(function(response){
            $scope.waiting = false;
            $scope.sale = response;
            if( $scope.sale.status == 'succeeded '){
                ngToast.success({
                    content: 'Compra realizada com sucesso',
                    dismissOnTimeout: true,
                    timeout: 5000
                });
                $localStorage.cart = null;
            } else {
                $scope.waiting = false;
                // ngToast.danger({
                //     content: 'Erro ao realizar compra. Tente outro cartão ou entre em contato com sua operadora',
                //     dismissOnTimeout: true,
                //     timeout: 5000
                // });
            }

        }, function(error){
            $sale.get({id: $stateParams.id}).$promise.then(function(response){
                $scope.waiting = false;
                $scope.sale = response;
                if( $scope.sale.status == 'succeeded'){
                    ngToast.success({
                        content: 'Compra realizada com sucesso',
                        dismissOnTimeout: true,
                        timeout: 5000
                    });
                    $localStorage.cart = null;
                } else {
                    $scope.waiting = false;
                    ngToast.danger({
                        content: 'Erro ao realizar compra. Tente outro cartão ou entre em contato com sua operadora',
                        dismissOnTimeout: true,
                        timeout: 5000
                    });
                }
            });
        });

    }

    $countryState.query({count:1000}).$promise.then(function(response){
        $scope.states = response.data;
    });
    $scope.queryCities = function() {
        $city.query({state_id:$scope.sale.buyer.address[0].state_id}).$promise.then(function(response){
            $scope.cities = response.data;
        });
    }
    $scope.getCep = function(){
        if( $scope.sale.buyer && $scope.sale.buyer.address[0] != undefined && $scope.sale.buyer.address[0].zip_code.length > 7 ){
            $scope.waiting = true;
            var url         = 'https://viacep.com.br/ws/'+$scope.sale.buyer.address[0].zip_code+'/json';
            var trustedUrl  = $sce.trustAsResourceUrl(url);
            $http.jsonp(trustedUrl, {jsonpCallbackParam: 'callback'}).then(function(response){
                if (response.data.logradouro != undefined && response.data.bairro != undefined){
                    $scope.sale.buyer.address[0].line1        = response.data.logradouro;
                    $scope.sale.buyer.address[0].neighborhood = response.data.bairro;

                    // Escolher UF
                    $countryState.query({abbreviation:response.data.uf}).$promise.then(function(response){
                        $scope.sale.buyer.address[0].state_id = response.id;
                    });

                    // Escolher Cidade
                    $city.query({ibge:response.data.ibge}).$promise.then(function(response){
                        $scope.cities = response.data;
                        $scope.sale.buyer.address[0].city_id = response.data[0].id;
                    });

                    $scope.sale.buyer.address[0].line2  = '';
                    $scope.sale.buyer.address[0].number = '';
                }
                $scope.waiting = false;
            }, function(error){
                $scope.waiting = false;
            });
        }
    };

    $scope.validDate = function(value) {
        if (!value)
        return false;

        value = value.split('/');

        if (value[0] && value[1]) {
            var date = moment(value[0].trim() + '/' + value[1].trim(), "MM/YY");
            return date.isValid() && date.isAfter(moment());
        }
    }

    $scope.validCard = function(value) {
        var deferred = $q.defer();
        $timeout(function() {
            var valid = angular.element('#number').hasClass('jp-card-valid');
            if (valid) {
                deferred.resolve(true);
            } else {
                deferred.reject(false);
            }
        }, 500);
        return deferred.promise;
    };

    $scope.removeItem = function(id) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$sale',
            function($state, $scope, $uibModalInstance, $sale) {

                $scope.title = 'Deseja remover esse item?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    $sale.removeItem(id).then(function(response){
                        $scope.getSale();
                        ngToast.danger({
                            content: 'Item removido',
                            dismissOnTimeout: true,
                            timeout: 2000
                        });
                        $uibModalInstance.close();
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro na operação',
                            dismissOnTimeout: true,
                            timeout: 2000
                        });
                        $uibModalInstance.close();
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.applyDiscount = function(coupon){
        $sale.applyDiscount(coupon, $stateParams.id).then(function(response){
            $scope.getSale();
            ngToast.success({
                content: 'Desconto aplicado',
                dismissOnTimeout: true,
                timeout: 2000
            });
        }, function(error){
            switch (error.data.message) {
                case 'invalid_coupon':
                    ngToast.danger({
                        content: 'Cupom inválido',
                        dismissOnTimeout: true,
                        timeout: 2000
                    });
                break;
                case 'Error on rule min':
                    ngToast.danger({
                        content: 'Requisito para cupom não alcançado',
                        dismissOnTimeout: true,
                        timeout: 2000
                    });
                break;
                default:
                    ngToast.danger({
                        content: 'Erro ao adicionar cupom',
                        dismissOnTimeout: true,
                        timeout: 2000
                    });
            }

        });
    }

    $scope.changeQtd = function(index, int){
        if( parseInt($scope.sale.items[index].quantity) + int < 1 )
            return;

        $scope.waiting = true;
        $scope.sale.items[index].quantity = parseInt($scope.sale.items[index].quantity) + int;

        $sale.updateEcommerce($scope.sale, $scope.sale.hashid).then(function(response){
            $localStorage.cart = response;
            $scope.sale = response;
            $scope.waiting = false;

        }, function(error){
            $scope.sale.items[index].quantity = parseInt($scope.sale.items[index].quantity) - int;
            console.log(error);
            ngToast.danger({
                content: error.data.message,
                dismissOnTimeout: true,
                timeout: 2000
            });
            $scope.waiting = false;

        });
    }
}]);

angular.module('cashier').
controller('select_client_card', ['$scope','$uibModalInstance','NgTableParams','$client', 'client_id',
function( $scope, $uibModalInstance,NgTableParams,$client, client_id ) {

    var $ctrl = this;

    $scope.is_card_saving   = false;
    $scope.title            = 'Cartões';
    $scope.cancel_text      = 'Cancelar';
    $scope.success_text     = 'Concluído';
    $scope.newCard          = false;

    $scope.placeholders = {
        number: '•••• •••• •••• ••••',
        name: 'Nome do titular',
        expiry: '••/••••',
        cvc: '•••'
    }

    $scope.messages = {
        validDate: 'válido\naté',
        monthYear: 'mês/ano',
    }

    $scope.card = {
        number: '',
        name: '',
        expiry: '',
        cvc: ''
    }

    // $client.getCards(client_id).then(function(response){
    //     $scope.queryCities = response;
    // });

    $scope.addCard = function(){
        $scope.newCard   = true;
    };

    $scope.selectCard = function(token) {
        data = {
            card : null,
            card_token : token
        }
        $uibModalInstance.close(data);
    }

    $scope.confirm = function() {

        $scope.is_card_saving = true;
        var exp = $scope.card.expiration.split('/');
        var exp = exp[0].trim() + exp[1].trim();
        $scope.card.expiration = exp;

        data = {
            card : $scope.card,
            card_token : null
        }

        $uibModalInstance.close(data);
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.validDate = function(value) {
        if (!value)
        return false;

        value = value.split('/');

        if (value[0] && value[1]) {
            var date = moment(value[0].trim() + '/' + value[1].trim(), "MM/YY");
            return date.isValid() && date.isAfter(moment());
        }
    }

    $scope.validCard = function(value) {
        var deferred = $q.defer();
        $timeout(function() {
            var valid = angular.element('#number').hasClass('jp-card-valid');
            if (valid) {
                deferred.resolve(true);
            } else {
                deferred.reject(false);
            }
        }, 500);
        return deferred.promise;
    };
}]);

angular.module('cashier').
controller('searching_sale', ['$scope', '$http', '$auth', '$q', '$uibModal', '$sale', 'ngToast', 'NgTableParams', '$invoice', '$service', '$rootScope', '$localStorage', '$cashier_movement', '$users', '$client', 'company_id', '$stateParams', '$uibModalInstance', '$rootScope', 'id',
function($scope, $http, $auth, $q, $uibModal, $sale, ngToast, NgTableParams, $invoice, $service, $rootScope, $localStorage, $cashier_movement, $users, $client, company_id, $stateParams, $uibModalInstance, $rootScope, id) {

    $scope.title = 'Pesquisa Detalhada';
    $scope.searchFinder = '';
    $scope.id = '';
    $scope.waiting      = true;
    $scope.devolutionSale = [];
    $scope.showDevolution = false;
    $scope.qtdMod = false;
    $scope.items = [];
    $scope.hasDevolutionbefore = false;
    $scope.today_opened = {
        opened: false
    }
    $scope.start_date = {
        opened: false
    }

    $scope.end_date = {
        opened: false
    }

    $scope.sale = {
        items           : [],
        payments        : [],
        value           : 0,
        discount        : 0,
        total           : 0
    }

    $scope.devolution = {
        items           : [],
        total           : 0,
        reduction_value : 0,
        sale_items_id   : []
    }

    if (company_id) {
        $scope.id = company_id;
    }

    if ($scope.id != '') {
        var str = '0';
    }else{
        var str =  document.getElementById('findDetail').value;
    }
    if (str == '') {
        ngToast.warning({
            content             : 'Insira os dados da busca!',
            dismissOnTimeout    : true,
            timeout             : 5000
        });
        return;
    }

    $scope.searchFinder       = str;

    $scope.params = {
        start_date   : moment().subtract(15, 'days').toDate(),
        end_date     : moment().toDate(),
        searchFinder : str
    }

    $scope.filterFromDevolution = function(data){
        $scope.params.from = data;
        $scope.params.status = null;
        $scope.search();
    }

    $scope.filterStatusDevolution = function(data){
        $scope.params.status = data;
        $scope.params.from = 'cashier';
        $scope.search();
    }

    if ($scope.id != '') {
        $scope.params.company_id = $scope.id;
    }

    if (id == 'newSale') {
        $scope.params.devolution = true;
    }

    $scope.search = function() {
        $scope.waiting      = true;
        $sale.saleSearch($scope.params).then(function(data){
            $scope.resultsSearch = data.data;
            $scope.waiting      = false;
            if (data.data.length == 0) {
                ngToast.danger({
                    content             : 'Venda não encontrada!',
                    dismissOnTimeout    : true,
                    timeout             : 5000
                });
                return;
            }
        });
    }
    $scope.search();

    $scope.return = function(){
        $uibModalInstance.close();
    }

    $scope.viewPayments = function(sale){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'view_payments.modal.html',
            size: 'lg',
            scope: $scope,
            backdrop        : 'static',
            controller: ['$scope', '$uibModalInstance', 'Upload',
            function($scope, $uibModalInstance) {
                console.log(sale);
                $scope.title = 'Venda Nº: ' + sale.number;
                $scope.sub_title = 'Vendedor: ' + sale.seller_name;
                $scope.sub_title_2 = 'Operador: ' + sale.user_name;
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'OK';
                $scope.server = server;


                $scope.sale = sale;
                if ($scope.sale.created_at) {
                    $scope.hour = $scope.sale.created_at.substr(11);
                }

                $scope.copy = function() {
                    var copyText = document.getElementById("linkSale");

                    copyText.select();
                    copyText.setSelectionRange(0, 99999);

                    document.execCommand("copy");
                }

                $scope.confirm = function() {
                    $uibModalInstance.close();
                }

                $scope.cancel = function() {
                    $uibModalInstance.close();
                };

            }]
        });
    }

    $scope.viewPaymentsDevolution = function(sale) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'view_payments.modal.html',
            size: 'lg',
            scope: $scope,
            backdrop        : 'static',
            controller: ['$scope', '$uibModalInstance', '$client', 'Upload', '$q',
            function($scope, $uibModalInstance, $client, Upload, $q) {

                $scope.title = 'Devolução';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'OK';
                $scope.server = server;
                $scope.showDevolution = true;


                $scope.sale = sale;
                _.each($scope.sale.items, function(item){
                    return $scope.items.push({ids: item.id, qtd: item.quantity});
                });
                if ($scope.sale.created_at) {
                    $scope.hour = $scope.sale.created_at.substr(11);
                }

                $scope.copy = function() {
                    var copyText = document.getElementById("linkSale");

                    copyText.select();
                    copyText.setSelectionRange(0, 99999);

                    document.execCommand("copy");
                }

                $scope.confirm = function() {
                    $uibModalInstance.close();
                }

                $scope.cancel = function() {
                    $uibModalInstance.close();
                };

            }]
        });

        $scope.goDevolution = function(row){
            if (row) {
                var next = _.find($scope.devolution.items, function(e){ return e.id == [row.id];});
                    if (next) {
                        ngToast.danger({
                            content: 'Item já adicionado!',
                            dismissOnTimeout: true,
                            timeout: 1000
                        });
                        return;
                    }
                    ngToast.success({
                        content: 'Item adicionado para devolução!',
                        dismissOnTimeout: true,
                        timeout: 1000
                    });
                $scope.devolution.items.push(row);

            }
        }

        $scope.clearList = function(){
            $scope.devolution = {};
        }

        $scope.modifyQuantity = function(){
            $scope.qtdMod = true;
        }

        $scope.applyChange = function(){
            var newItems = [];
            _.each($scope.devolution.items, function(e){ return newItems.push({ids: e.id, qtd: e.quantity}); });

            for (var i = 0, len = newItems.length; i < len; i++){
                if (parseFloat(newItems[i].qtd) > parseFloat($scope.items[i].qtd)){
                   ngToast.danger({
                       content: 'Quantidade de itens da devolução é maior do que foi vendido!',
                       dismissOnTimeout: true,
                       timeout: 3000
                   });
                   return;
                }
            }

            $scope.qtdMod = false;
        }

        $scope.confirmDevolution = function(){
            $scope.devolution.reduction_value = _.reduce($scope.devolution.items, function(total, row){ return (total + (parseFloat(row.value) * row.quantity)); }, 0);
            var itemsId  = _.each($scope.devolution.items, function(item){return $scope.devolution.sale_items_id.push(item.id); });
            $uibModalInstance.close({find: $scope.devolution});
            modalInstance.dismiss();
        }
    }

    $scope.print = function(row){
        $scope.printing = true;
        $http.get(api+'sale/'+row+'/print').then(function(response){
            $scope.printing = false;
            $rootScope.print(response.data);
            if($scope.company.category_print == 1){
              setTimeout(function () {
                  window.onfocus = $scope.printCategory(row);
              }, 100);
            }
        }, function(error) {
            ngToast.danger({
                content: 'Sem dados para impressão!',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.printing = false;
        });

    }

    $scope.makeInvoice = function(id){
        var modalInstance = $uibModal.open({
            backdrop        : 'static',
            animation: true,
            templateUrl: 'select.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance',
            function($state, $scope, $uibModalInstance) {

                $scope.title = 'Selecione o tipo de nota';
                $scope.cancel_text = 'Não';
                $scope.optionLeft = {
                    label   : 'NFe',
                    value   : 55
                }
                $scope.optionRight = {
                    label   : 'NFCe',
                    value   : 65
                }

                $scope.confirm = function(data) {

                    if(data == 65 && !$scope.input){
                        $scope.input = {
                            label   : 'Deseja incluir CPF na nota?',
                            type    : 'text',
                            mask    : 'ui-br-cpfcnpj-mask',
                            value   : null
                        }
                        $scope.optionRight.label = 'Continuar';
                        return;
                    }
                    data = {
                        mod : data,
                        cpf : $scope.input ? $scope.input.value : null
                    }
                    $uibModalInstance.close(data);
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]


        });

        modalInstance.result.then(function(data) {
            if( $scope.waiting )
                return;
                    
            $scope.waiting = true;
            var params = {
                sale_id     : id,
                mod         : data.mod,
                dest_cpf    : data.cpf

            }
            $http.post(api+'invoice/store_sale', params).then(function(response){
                var invoice = response.data;
                
                $invoice.send(invoice.id, {movement: false}).then(function(response){
                    $scope.waiting = false;
                    if (invoice.mod == '0') {
                        $invoice.printNFSe(invoice.id).then(function(response){
                            $rootScope.print(response); 
                        });
                    } else {
                        $invoice.print(invoice.id).then(function(print){
                            var file = new Blob([print.data], {type: 'application/pdf'});
                            var fileURL = URL.createObjectURL(file);

                            var link = angular.element('<a href="javascript: w=window.open(\''+fileURL+'\'); setTimeout(function () { w.print();w.onfocus=function(){ w.close();} }, 10);"></a>');
                            angular.element(document.body).append(link);
                            link[0].click();
                            link.remove();
                        });
                    }
                    $scope.tableParams.reload();
                    // $uibModalInstance.close();
                }, function(error){
                    $scope.waiting = false;
                    ngToast.danger({
                        content: error.data.message,
                        dismissOnTimeout    : false,
                        dismissButton       : true,
                    });
                    $scope.tableParams.reload();
                    // $uibModalInstance.dismiss('cancel');
                });
            }, function(error) {
                $scope.waiting = false;
                ngToast.danger({
                    content: error.data.message,
                    dismissOnTimeout    : false,
                    dismissButton       : true,
                });
                $scope.tableParams.reload();
                // $uibModalInstance.dismiss('cancel');
            });
        });
    }

    $scope.showInvoice = function(invoice){
        if( invoice.status != 'authorized' ){
            var modalInstance = $uibModal.open({
                animation      : true,
                ariaLabelledBy : 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl    : server+"/views/m/invoice/show",
                controller     : 'invoice.show',
                size           : 'lg',
                resolve        : {
                    invoice_id     : invoice.id,
                    showInModal    : true
                }
            });
            modalInstance.result.then(function() {
                $scope.tableParams.reload();
                $uibModalInstance.close();
            });
        } else {
            if (invoice.mod == '0' ){
                $invoice.printNFSe(invoice.id).then(function(response){
                    $rootScope.print(response); 
                });
            } else {
                $invoice.print(invoice.id).then(function(response){
                    var file = new Blob([response.data], {type: 'application/pdf'});
                    var fileURL = URL.createObjectURL(file);

                    // Create a link element on body
                    // var link = angular.element('<a href="' + fileURL + '" target="_blank"></a>');
                    var link = angular.element('<a href="javascript: w=window.open(\''+fileURL+'\'); setTimeout(function () { w.print();w.onfocus=function(){ w.close();} }, 10);"></a>');

                    angular.element(document.body).append(link);
                    link[0].click();
                    link.remove();
                });
            }
        }
    }

    $scope.sendToshipping = function(row){
        $sale.update({id : row.hashid}, {shipping_type : 'delivering'}).$promise.then(function(data) {
            $scope.search();
            ngToast.success({
                content: 'Venda enviada para Entregas!',
                dismissOnTimeout: true,
                timeout: 5000
            });
        }, function(error){
            ngToast.danger({
                content: 'Erro inexperado',
                dismissOnTimeout: true,
                timeout: 5000
            });
        });
    }

    $scope.canceleSale = function(id, bCredntials = true){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$client', function($state, $scope, $uibModalInstance, $client) {

                $scope.title = 'Essa operação não pode ser desfeita. Tem certeza?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.bCredntials  = bCredntials;
                $scope.bReason      = true;
                $scope.credentials ={
                    reason      : '',
                    user_id     : '',
                    password    : ''
                }

                $scope.confirm = function() {
                    $uibModalInstance.close($scope.credentials);
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function(data) {
            $scope.waiting = true;
            $sale.cancel(id, data).then(function(response){
                $scope.waiting = false;
                ngToast.success({
                    content: 'Venda cancelada',
                    dismissOnTimeout: true,
                    timeout: 5000
                });
                $scope.$parent.tableParams.reload();
            }, function(error){
                $scope.waiting = false;
                ngToast.danger({
                    content: 'Error ao deletar venda',
                    dismissOnTimeout: true,
                    timeout: 5000
                });
            });
        });
    }
    $scope.openSale = function(row){
        row.funciton = 'openSale';
        $uibModalInstance.close(row);
    }

}]);

angular.module('cashier').
factory("$sale", function($resource, $filter, $q, $http) {

    var resource = $resource(api + 'sale/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.checkout = function(data, id)
    {
        var deferred = $q.defer();
        $http.post(api+'sale/'+id+'/checkout', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.checkClient = function(data, id)
    {
        var deferred = $q.defer();
        $http.post(api+'sale/'+id+'/check_client', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.saveEcommerce = function(data)
    {
        var deferred = $q.defer();
        $http.post(api+'sale/save_ecommerce', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.applyDiscount = function(voucher, id)
    {
        var deferred = $q.defer();
        $http.post(api+'sale/'+id+'/apply_discount', {voucher : voucher}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.updateEcommerce = function(data, id)
    {
        var deferred = $q.defer();
        $http.put(api+'sale/'+id+'/update_ecommerce', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.updateBuyer = function(data, id)
    {
        var deferred = $q.defer();
        $http.put(api+'sale/'+id+'/update_buyer', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.removeItem = function(id)
    {
        var deferred = $q.defer();
        $http.delete(api+'sale/delete_item/'+id).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.cancel = function(id, data)
    {
        var deferred = $q.defer();
        $http.put(api+'sale/'+id+'/cancel',data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.applyShipping = function(data, id)
    {
        var deferred = $q.defer();
        $http.post(api+'sale/'+id+'/apply_shipping',data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.calcShipping = function(id)
    {
        var deferred = $q.defer();
        $http.get(api+'sale/'+id+'/calc_shipping').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.salesBalance = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'report/sales_balance', {params: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.salesToSeller = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'report/sales_to_seller', {params: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

     resource.commissionSalesToSeller = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'report/commission_sales_to_seller', {params: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.printSale = function(data, id)
    {
        var deferred = $q.defer();
        $http.get(api+'sale/'+id+'/print', {params:data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.salesToClient = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'report/sales_to_client', {params: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.report = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'sale_report', {params: data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.saleSearch = function(data)
    {
        var deferred = $q.defer();
        $http.get(api+'sale_search', {params : data}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
});

angular.module('cashier').
factory("$cashier_movement", function($resource, $q, $http) {
    var resource = $resource(api + 'cashier_movement', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        },
        'post': {
            method: 'POST'
        }
    });

    return resource;
});

angular.module('cashier').
factory("$sale_item", function($resource, $q, $http) {
    var resource = $resource(api + 'sale_item', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        },
        'post': {
            method: 'POST'
        }
    });

    return resource;
});

angular.module('cashier').
factory("$suggestion", function($resource, $q, $http) {

    var resource = $resource(api + 'suggestion', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        },
        'post': {
            method: 'POST'
        }

    });
    return resource;
});
